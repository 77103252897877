@media screen  and (max-width:600px) and (orientation : portrait){
    html {
        box-sizing: border-box;
      }
      *, *:before, *:after {
        box-sizing: inherit;
      }
   
    .header{
        height: 7vh;
        width: 100vw;
        border-bottom: 1px solid black;
        display: flex;
        justify-content: space-between;
        /* padding: 0.5%; */
        /* align-items: flex-start; */
        overflow:hidden;
    }
    
    
    .icon{
        align-self: center;
        
    }

    .react-icons{
        /* vertical-align: middle; */
        font-size: 200%;
        
    }
    
    .mac-address {
        /* align-self: center; */
        /* margin-left: 40vw; */
        font-size: 100%;
        /* border-right: 40px; */
        /* padding-top: 2.5%; */
        /* padding-bottom: 1.5%; */
        margin: auto;
        /* padding-bottom: 5%; */
    }

    .mac-address h3{
        margin: auto;
    }

    .graph{
        width: 100vw;
        height: 43vh;
    }
    
    
    .graph1{
        height: 37vh;
        width: 99vw;
        margin: auto;
        display: inline-flex;
        
    }

    .line-chart{
        height: 100%;
        width: 100%;

    }

    .graph-sidepanel-open-button{
        position: absolute;
        right: 0px;
        padding: 0;
        margin: 0;
    }

    .side-panel  {
        width:  0px;
        position: fixed;
        z-index: 1;
        height: 250px;
        top: 7vh;
        right: 0px;
        background-color: #111;
        /* overflow-x: hidden; */
        transition: 0.5s;
        padding-top: 60px;
        /* overflow-y: scroll; */
        
      }

      

      .side-panel > button {
        /* padding: 8px 8px 8px 32px; */
        margin: auto;
        text-align: center;
        text-decoration: none;
        font-size: 25px;
        font-family:Verdana, Geneva, Tahoma, sans-serif;
        color: #818181;
        display: block;
        transition: 0.3s;
        cursor: pointer;
        /* border-radius: 10px; */
        background-color: transparent;
        border: none;
      }

      .side-panel > button:hover {
       color: #ffffff;
       background-color: gray;
       border-color: #e6f20c;
       border-radius: 10px;
      }

      #closebutton {
        position: absolute;
        margin-left: 10px;
        top: 0;
        font-size: 36px;
        color:  #000000;
        border: none;
        padding: 0;
        margin-top: 4px;
        background-color: transparent;
        cursor: pointer;
        
      }

      #closebutton :hover {
       background: none;
        
      }
      


    .openbtn {
        font-size: 20px;
        cursor: pointer;
        background-color: #111;
        color: white;
        padding: 10px 15px;
        border: none;
      }
    
    .openbtn  :hover {
        color: #b30000;
        background-color: #b30000;
      }



    

    .parameter{
        height: 7vh;
        width: 100%;
        border-radius: 7px;
        margin-top: 0.25rem;
        display: inline-flex;
        padding: 5px;
        
    }

    .parameter > .label2 > label{
        /* border: 1px solid black; */
        display:inline;
        font-size: 100%;
        
    }


    .parameter_ul{
        width: 60%;
        height: 100%;
        
        /* border: 1px solid black; */
        /* display: contents; */
        
    }


    .parameter_ul>ul{
        margin: 0px;
        padding: 0.5%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        /* border: 1px solid black; */
        /* margin-top: -1.2rem; */
        /* justify-content: space-evenly; */
        /* margin-left: 20%; */
    } 

    .parameter_ul > ul > li{
        margin: 0px;
        /* border: 1px solid blue; */
        
    }

    #humid{
        background-color: rgba(107, 119, 227, 0.8);
    }
    
    
    #temp{
        background-color: rgba(222, 60, 99,0.8); 
    }

    
    #lux{
        background-color: rgba(55, 203, 149,0.8);
    }

    
    #motion1{
        background-color: rgba(13, 244, 159, 0.801);
    }
   

    #battery{
        background-color: rgba(255, 255, 0, 0.4);
    }
    

    #voltage{
        background-color: rgba(255, 255, 0, 0.4);
    }


    #pressure{
        background-color: rgba(145, 200, 228, 1);
    }
    

    #gx{
        background-color: rgba(168, 161, 150, 1);
    }
    

    #gy{
        background-color: rgba(255, 217, 192, 1);
    }
    
    
    #gz{
        background-color: rgba(239, 98, 98, 1);
    }
    

    #err{
        background-color: rgba(160, 191, 224, 1);
    }
    
    

    #imei{
        background-color: rgba(203, 255, 169, 1);
    }
    

    #csq{
        background-color: rgba(79, 192, 208, 1);
    }
    

    #fwv{
        background-color: rgba(255, 137, 137, 1);
    }


    #rr{
        background-color: rgba(255, 33, 113, 1);
    }

    #distance_travelled{
        background-color: rgba(168, 161, 150, 1);
    }

    #offline-humidity{
        background-color: rgba(107, 119, 227, 0.349);
    }
    
    #offline-temperature{
        background-color: rgba(222, 60, 98, 0.349);
    }

    #offline-lux{
        background-color: rgba(55, 203, 149,0.349);
    }

    #offline-motion{
        background-color: rgba(13, 244, 159, 0.378);
    }

    #offline-voltage{
        background-color: rgba(255, 255, 0, 0.133);
    }

    #offline-pressure{
        background-color: rgba(145, 200, 228, 0.511);
    }

    #offline-gx{
        background-color: rgba(168, 161, 150, 0.624);
    }

    #offline-gy{
        background-color: rgba(255, 217, 192, 0.352);
    }

    #offline-gz{
        background-color: rgba(239, 98, 98, 0.534);
    }

    #offline-err{
        background-color: rgba(160, 191, 224, 0.438);
    }
    
    #offline-csq{
        background-color: rgba(79, 193, 208, 0.487);
    }

    #offline-rr{
        background-color: rgba(255, 33, 114, 0.655);
    }

    #offline-gsmerr{
        background-color: rgba(159, 104, 59, 0.655);
    }



    
    .footer{
        height: 7vh;
        width: 100vw;
        border-top: 1px solid black;
        display: flex;
        justify-content: space-around;
        align-items: center;

    }


        
    /* .stat{
        margin-top: 0.5rem;
        padding-top: 2.5%;
        border: 1px solid black;
    }
    
    .route{
        margin-top: 0.25rem;
    } */



    li {
        font-size: 12px;
        font-weight: 500;
        /* text-align: left; */
        margin-left: 19%;
        
    }

    ul {
        text-align: center;
    }

    .label1{
        
        /* width:140px; */
        margin-right:199px;
        
        
    }

    .label2{
        width: 40%;
        height: 100%;
        
        
        /* overflow-wrap: break-word; */
        /* word-wrap: break-word; */
        
        /* margin-right: 70%; */
        word-break: break-all;
        -ms-word-break: break-all;
        /* padding-right: 10px; */
        /* text-align: center; */
        hyphens:auto;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        display: inline-block;
        /* border: 1px solid black; */

    }

    #resetbutton{
              /* display: flex;   */
              /* justify-content: flex-start; */
              /* display: inline-block; */
        margin-right: 2%;
              
    }
    .button2{
        background-color: white; /* Green */
        border: 2px solid #4CAF50;
        color: black;
        padding: 5px 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 7px;
        margin: 2px 1px;
        transition-duration: 0.4s;
        cursor: pointer;
    }

    .button2:hover {
        background-color: #4CAF50;
        color: white;
    }

    .button3{
        background-color: white; /* Green */
        border: 2px solid #008CBA;
        color: black;
        padding: 5px 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 7px;
        margin: 2px 1px;
        transition-duration: 0.4s;
        cursor: pointer;
    }

    .button3:hover {
        background-color: #008CBA;
        color: white;
    }

    .button4{
        background-color: white; /* Green */
        border: 2px solid #f44336;
        color: black;
        padding: 5px 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 7px;
        margin: 2px 1px 2px 1px;
        transition-duration: 0.4s;
        cursor: pointer;
    }

    .button4:hover {
        background-color: #f44336;
        color: white;
    }

  


    .version{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 2vh;
        width: 100vw;
       
    }
    .version>h6{
        margin-right: 0.3%;
    }


    /* sizing of map container */

    .map_page_main_div{
        height: 86vh;
        width: 100vw;
    }

    .leaflet-container{
        height: 100%;
        width: 100%;

    }

    /* .markercluster-map { height: 90vh; } */
    

    .leaflet-div-icon {
        background: transparent;
        border: none;
    }
    
    .leaflet-marker-icon .number{
        position: relative;
        top: -37px;
        font-size: 12px;
        width: 25px;
        text-align: center;
    }

    #customdates{
        /* border: 1px solid black; */
        margin-left: 2%;
        
    }



    input[type="date"]{
        background-color: #0080ff;
        padding: 2px;
        /* position: absolute; */
        font-family: "Roboto Mono", monospace;
        color: white;
        font-size: 5px;
        border-radius: 5px;
        margin: 1px 1px 1px 1px;
    }

    ::-webkit-calendar-picker-indicator{
        background-color: white;
        padding: 5px;
        cursor: pointer;
        border-radius: 3px;
    }

    .button-dates{
           display: inline-flex;
           width: 100vw;
           height: auto;
           justify-content: space-between;
           margin-top: 0.5%;
    }
    

    @media print{@page {size: landscape;}}
   

    /* .mapid{
        border: 1px solid black;
        
    } */

    /* } */


    #routedata{
        height: 40%;
        margin-top: 25%;
        
    }

    /* #mapfooter{
        
    } */


    






    .csvlink-btn{
        background-color: #ffffff;
        border: none;
        cursor: pointer;
    }

    /* .btnContainer{
        
    } */



    .overlay{
        
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(189,189,189,0.9);
        
    }

    .modalContainer{
        position: fixed;
        max-width: 25%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: #ffffff;
        /* height: 40%;  */
        box-shadow: 2.5px 2.5px 10px 1px;
        border-radius: 20px 20px 20px 20px;
        display: flex;
        /* justify-content: flex-end; */
        /* align-items: flex-start; */
        flex-direction: column;
    }

    .btn-print-graph{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #008CBA;
        color: black;
        padding: 10%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 10%;
        /* margin-left: 19%; */
        margin-bottom: 5%;
        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
        
    }

    .btn-print-graph:hover{
        background-color: #008CBA;
        color: white;
        border-radius: 20px 20px 20px 20px;
        
        
    }

    .btn-csv{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #4CAF50;
        color: black;
        padding: 5%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 14%;
        
        /* margin-left: 19%; */

        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
    }

    .btn-csv:hover{
        background-color: #4CAF50;
        color: white;
        border-radius: 20px 20px 20px 20px;
    }


    .btn-offline-csv{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #d60e0e;
        color: black;
        padding: 5%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 14%;
        
        /* margin-left: 19%; */

        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
    }

    .btn-offline-csv:hover{
        background-color: #d60e0e;
        color: white;
        border-radius: 20px 20px 20px 20px;
    }

    .closing-btn{
            margin-right: 4%;
            margin-top: 2.5%;
            border: none;
            background-color: #ffffff;
            font-size: 20px;
            cursor: pointer;
            align-self: flex-end;
    }

    .scheduled_date-btn{
        background-color: #000000;
        padding: 5px;
        /* position: absolute; */
        font-family: "Roboto Mono", monospace;
        color: white;
        font-size: 5px;
        border-radius: 5px;
        margin: 2px;
        transition: 0.4s;
    }

    .scheduled_date-btn:hover{
        color: black;
        background-color: white;
    }


    .scrollable_div{
        
        margin-left: 0.5%;
        margin-right: 0.5%;
        margin-bottom: 0.5%;
        
        padding:0px 5px 5px 5px;
        height: 40vh;
        width: 99vw;
        overflow-y: scroll;
        /* border: 2px solid black; */
        border-radius: 5px;
        
    }

    .scrollable_div::-webkit-scrollbar {
        width: 5px;
    }

    .scrollable_div::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
    }

    .scrollable_div::-webkit-scrollbar-thumb {
        background: black; 
        border-radius: 10px;
    } 

    .scrollable_div::-webkit-scrollbar-thumb:hover {
        background: #b30000; 
    }  


    .component-modal-body{
        height: 25vh;
        width: 99vw;
        border: 2px solid black;
        background-color: #fff;
        border-radius: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .closing-modal-btn{
        width: 45px;
        height: 40px;
        background-color: #000;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        font-size: 150%;
        position:absolute;
        /* padding:  7px 5px 5px 17px; */
        margin-left: -0.445%;
        margin-top: -0.445%;
        cursor: pointer;
        
    }

    .datapoint_count{
        height: 10px;
        width: 10px;
        background-color: #000;
        color: #fff;
    }

}

@media screen  and (max-width:600px) and (orientation : landscape){
    html {
        box-sizing: border-box;
      }
      *, *:before, *:after {
        box-sizing: inherit;
      }
   
    .header{
        height: 7vh;
        width: 100vw;
        border-bottom: 1px solid black;
        display: flex;
        justify-content: space-between;
        /* padding: 0.5%; */
        /* align-items: flex-start; */
        overflow:hidden;
    }
    
    
    .icon{
        align-self: center;
        
    }

    .react-icons{
        /* vertical-align: middle; */
        font-size: 200%;
        
    }
    
    .mac-address {
        /* align-self: center; */
        /* margin-left: 40vw; */
        font-size: 100%;
        /* border-right: 40px; */
        /* padding-top: 2.5%; */
        /* padding-bottom: 1.5%; */
        margin: auto;
        /* padding-bottom: 5%; */
    }

    .mac-address h3{
        margin: auto;
    }

    .graph{
        width: 100vw;
        height: 43vh;
    }
    
    
    .graph1{
        height: 80%;
        width: 99vw;
        margin: auto;
        display: inline-flex;
        
    }

    .line-chart{
        height: 100%;
        width: 100%;

    }

    .graph-sidepanel-open-button{
        position: absolute;
        right: 0px;
        padding: 0;
        margin: 0;
    }

    .side-panel  {
        width:  0px;
        position: fixed;
        z-index: 1;
        height: 250px;
        top: 7vh;
        right: 0px;
        background-color: #111;
        /* overflow-x: hidden; */
        transition: 0.5s;
        padding-top: 60px;
        /* overflow-y: scroll; */
        
      }

      

      .side-panel > button {
        /* padding: 8px 8px 8px 32px; */
        margin: auto;
        text-align: center;
        text-decoration: none;
        font-size: 25px;
        font-family:Verdana, Geneva, Tahoma, sans-serif;
        color: #818181;
        display: block;
        transition: 0.3s;
        cursor: pointer;
        /* border-radius: 10px; */
        background-color: transparent;
        border: none;
      }

      .side-panel > button:hover {
       color: #ffffff;
       background-color: gray;
       border-color: #e6f20c;
       border-radius: 10px;
      }

      #closebutton {
        position: absolute;
        margin-left: 10px;
        top: 0;
        font-size: 36px;
        color:  #000000;
        border: none;
        padding: 0;
        margin-top: 4px;
        background-color: transparent;
        cursor: pointer;
        
      }

      #closebutton :hover {
       background: none;
        
      }
      


    .openbtn {
        font-size: 20px;
        cursor: pointer;
        background-color: #111;
        color: white;
        padding: 10px 15px;
        border: none;
      }
    
    .openbtn  :hover {
        color: #b30000;
        background-color: #b30000;
      }



    

    .parameter{
        height: 7vh;
        width: 100%;
        border-radius: 7px;
        margin-top: 0.25rem;
        display: inline-flex;
        padding: 5px;
        
    }

    .parameter > .label2 > label{
        /* border: 1px solid black; */
        display:inline;
        font-size: 100%;
        
    }


    .parameter_ul{
        width: 60%;
        height: 100%;
        
        /* border: 1px solid black; */
        /* display: contents; */
        
    }


    .parameter_ul>ul{
        margin: 0px;
        padding: 0.5%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        /* border: 1px solid black; */
        /* margin-top: -1.2rem; */
        /* justify-content: space-evenly; */
        /* margin-left: 20%; */
    } 

    .parameter_ul > ul > li{
        margin: 0px;
        /* border: 1px solid blue; */
        
    }

    #humid{
        background-color: rgba(107, 119, 227, 0.8);
    }
    
    
    #temp{
        background-color: rgba(222, 60, 99,0.8); 
    }

    
    #lux{
        background-color: rgba(55, 203, 149,0.8);
    }

    
    #motion1{
        background-color: rgba(13, 244, 159, 0.801);
    }
   

    #battery{
        background-color: rgba(255, 255, 0, 0.4);
    }
    

    #voltage{
        background-color: rgba(255, 255, 0, 0.4);
    }


    #pressure{
        background-color: rgba(145, 200, 228, 1);
    }
    

    #gx{
        background-color: rgba(168, 161, 150, 1);
    }
    

    #gy{
        background-color: rgba(255, 217, 192, 1);
    }
    
    
    #gz{
        background-color: rgba(239, 98, 98, 1);
    }
    

    #err{
        background-color: rgba(160, 191, 224, 1);
    }
    
    

    #imei{
        background-color: rgba(203, 255, 169, 1);
    }
    

    #csq{
        background-color: rgba(79, 192, 208, 1);
    }
    

    #fwv{
        background-color: rgba(255, 137, 137, 1);
    }


    #rr{
        background-color: rgba(255, 33, 113, 1);
    }

    #distance_travelled{
        background-color: rgba(168, 161, 150, 1);
    }

    #offline-humidity{
        background-color: rgba(107, 119, 227, 0.349);
    }
    
    #offline-temperature{
        background-color: rgba(222, 60, 98, 0.349);
    }

    #offline-lux{
        background-color: rgba(55, 203, 149,0.349);
    }

    #offline-motion{
        background-color: rgba(13, 244, 159, 0.378);
    }

    #offline-voltage{
        background-color: rgba(255, 255, 0, 0.133);
    }

    #offline-pressure{
        background-color: rgba(145, 200, 228, 0.511);
    }

    #offline-gx{
        background-color: rgba(168, 161, 150, 0.624);
    }

    #offline-gy{
        background-color: rgba(255, 217, 192, 0.352);
    }

    #offline-gz{
        background-color: rgba(239, 98, 98, 0.534);
    }

    #offline-err{
        background-color: rgba(160, 191, 224, 0.438);
    }
    
    #offline-csq{
        background-color: rgba(79, 193, 208, 0.487);
    }

    #offline-rr{
        background-color: rgba(255, 33, 114, 0.655);
    }

    #offline-gsmerr{
        background-color: rgba(159, 104, 59, 0.655);
    }


    


    
    .footer{
        height: 7vh;
        width: 100vw;
        border-top: 1px solid black;
        display: flex;
        justify-content: space-around;
        align-items: center;

    }


        
    /* .stat{
        margin-top: 0.5rem;
        padding-top: 2.5%;
        border: 1px solid black;
    }
    
    .route{
        margin-top: 0.25rem;
    } */



    li {
        font-size: 12px;
        font-weight: 500;
        /* text-align: left; */
        margin-left: 19%;
        
    }

    ul {
        text-align: center;
    }

    .label1{
        
        /* width:140px; */
        margin-right:199px;
        
        
    }

    .label2{
        width: 40%;
        height: 100%;
        
        
        /* overflow-wrap: break-word; */
        /* word-wrap: break-word; */
        
        /* margin-right: 70%; */
        word-break: break-all;
        -ms-word-break: break-all;
        /* padding-right: 10px; */
        /* text-align: center; */
        hyphens:auto;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        display: inline-block;
        /* border: 1px solid black; */

    }

    #resetbutton{
              /* display: flex;   */
              /* justify-content: flex-start; */
              /* display: inline-block; */
        margin-right: 2%;
              
    }
    .button2{
        background-color: white; /* Green */
        border: 2px solid #4CAF50;
        color: black;
        padding: 5px 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 7px;
        margin: 2px 1px;
        transition-duration: 0.4s;
        cursor: pointer;
    }

    .button2:hover {
        background-color: #4CAF50;
        color: white;
    }

    .button3{
        background-color: white; /* Green */
        border: 2px solid #008CBA;
        color: black;
        padding: 5px 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 7px;
        margin: 2px 1px;
        transition-duration: 0.4s;
        cursor: pointer;
    }

    .button3:hover {
        background-color: #008CBA;
        color: white;
    }

    .button4{
        background-color: white; /* Green */
        border: 2px solid #f44336;
        color: black;
        padding: 5px 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 7px;
        margin: 2px 1px 2px 1px;
        transition-duration: 0.4s;
        cursor: pointer;
    }

    .button4:hover {
        background-color: #f44336;
        color: white;
    }

  


    .version{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 2vh;
        width: 100vw;
       
    }
    .version>h6{
        margin-right: 0.3%;
    }


    /* sizing of map container */

    .map_page_main_div{
        height: 86vh;
        width: 100vw;
    }

    .leaflet-container{
        height: 100%;
        width: 100%;

    }

    /* .markercluster-map { height: 90vh; } */

    .leaflet-div-icon {
        background: transparent;
        border: none;
    }
    
    .leaflet-marker-icon .number{
        position: relative;
        top: -37px;
        font-size: 12px;
        width: 25px;
        text-align: center;
    }

    #customdates{
        /* border: 1px solid black; */
        margin-left: 2%;
        
    }



    input[type="date"]{
        background-color: #0080ff;
        padding: 2px;
        /* position: absolute; */
        font-family: "Roboto Mono", monospace;
        color: white;
        font-size: 5px;
        border-radius: 5px;
        margin: 1px 1px 1px 1px;
    }

    ::-webkit-calendar-picker-indicator{
        background-color: white;
        padding: 5px;
        cursor: pointer;
        border-radius: 3px;
    }

    .button-dates{
           display: inline-flex;
           width: 100vw;
           height: 20%;
           justify-content: space-between;
           margin-top: 0.5%;
    }
    

    @media print{@page {size: landscape;}}
   

    /* .mapid{
        border: 1px solid black;
        
    } */

    /* } */


    #routedata{
        height: 40%;
        margin-top: 25%;
        
    }

    /* #mapfooter{
        
    } */


    






    .csvlink-btn{
        background-color: #ffffff;
        border: none;
        cursor: pointer;
    }

    /* .btnContainer{
        
    } */



    .overlay{
        
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(189,189,189,0.9);
        
    }

    .modalContainer{
        position: fixed;
        max-width: 25%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: #ffffff;
        /* height: 40%;  */
        box-shadow: 2.5px 2.5px 10px 1px;
        border-radius: 20px 20px 20px 20px;
        display: flex;
        /* justify-content: flex-end; */
        /* align-items: flex-start; */
        flex-direction: column;
    }

    .btn-print-graph{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #008CBA;
        color: black;
        padding: 10%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 10%;
        /* margin-left: 19%; */
        margin-bottom: 5%;
        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
        
    }

    .btn-print-graph:hover{
        background-color: #008CBA;
        color: white;
        border-radius: 20px 20px 20px 20px;
        
        
    }

    .btn-csv{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #4CAF50;
        color: black;
        padding: 5%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 14%;
        
        /* margin-left: 19%; */

        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
    }

    .btn-csv:hover{
        background-color: #4CAF50;
        color: white;
        border-radius: 20px 20px 20px 20px;
    }

    .btn-offline-csv{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #d60e0e;
        color: black;
        padding: 5%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 14%;
        
        /* margin-left: 19%; */

        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
    }

    .btn-offline-csv:hover{
        background-color: #d60e0e;
        color: white;
        border-radius: 20px 20px 20px 20px;
    }

    .closing-btn{
            margin-right: 4%;
            margin-top: 2.5%;
            border: none;
            background-color: #ffffff;
            font-size: 20px;
            cursor: pointer;
            align-self: flex-end;
    }

    .scheduled_date-btn{
        background-color: #000000;
        padding: 5px;
        /* position: absolute; */
        font-family: "Roboto Mono", monospace;
        color: white;
        font-size: 5px;
        border-radius: 5px;
        margin: 2px;
        transition: 0.4s;
    }

    .scheduled_date-btn:hover{
        color: black;
        background-color: white;
    }


    .scrollable_div{
        
        margin-left: 0.5%;
        margin-right: 0.5%;
        margin-bottom: 0.5%;
        
        padding:0px 5px 5px 5px;
        height: 40vh;
        width: 99vw;
        overflow-y: scroll;
        /* border: 2px solid black; */
        border-radius: 5px;
        
    }

    .scrollable_div::-webkit-scrollbar {
        width: 5px;
    }

    .scrollable_div::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
    }

    .scrollable_div::-webkit-scrollbar-thumb {
        background: black; 
        border-radius: 10px;
    } 

    .scrollable_div::-webkit-scrollbar-thumb:hover {
        background: #b30000; 
    }  

    .component-modal-body{
        height: 25vh;
        width: 99vw;
        border: 2px solid black;
        background-color: #fff;
        border-radius: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .closing-modal-btn{
        width: 45px;
        height: 40px;
        background-color: #000;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        font-size: 150%;
        position:absolute;
        /* padding:  7px 5px 5px 17px; */
        margin-left: -0.445%;
        margin-top: -0.445%;
        cursor: pointer;
        
    }

    .datapoint_count{
        height: 10px;
        width: 10px;
        background-color: #000;
        color: #fff;
    }


}



@media screen  and (min-width:600px) and (orientation : portrait){
    html {
        box-sizing: border-box;
      }
      *, *:before, *:after {
        box-sizing: inherit;
      }
   
    .header{
        height: 7vh;
        width: 100vw;
        border-bottom: 1px solid black;
        display: flex;
        justify-content: space-between;
        /* padding: 0.5%; */
        /* align-items: flex-start; */
        overflow:hidden;
    }
    
    
    .icon{
        align-self: center;
        
    }

    .react-icons{
        /* vertical-align: middle; */
        font-size: 200%;
        
    }
    
    .mac-address {
        /* align-self: center; */
        /* margin-left: 40vw; */
        font-size: 100%;
        /* border-right: 40px; */
        /* padding-top: 2.5%; */
        /* padding-bottom: 1.5%; */
        margin: auto;
        /* padding-bottom: 5%; */
    }

    .mac-address h3{
        margin: auto;
    }

    .graph{
        width: 100vw;
        height: 43vh;
    }
    
    
    .graph1{
        height: 37vh;
        width: 99vw;
        margin: auto;
        display: inline-flex;
    }


    .line-chart{
        height: 100%;
        width: 100%;

    }

    .graph-sidepanel-open-button{
        position: absolute;
        right: 0px;
        padding: 0;
        margin: 0;
    }

    .side-panel  {
        width:  0px;
        position: fixed;
        z-index: 1;
        height: 250px;
        top: 7vh;
        right: 0px;
        background-color: #111;
        /* overflow-x: hidden; */
        transition: 0.5s;
        padding-top: 60px;
        /* overflow-y: scroll; */
        
      }

      

      .side-panel > button {
        /* padding: 8px 8px 8px 32px; */
        margin: auto;
        text-align: center;
        text-decoration: none;
        font-size: 25px;
        font-family:Verdana, Geneva, Tahoma, sans-serif;
        color: #818181;
        display: block;
        transition: 0.3s;
        cursor: pointer;
        /* border-radius: 10px; */
        background-color: transparent;
        border: none;
      }

      .side-panel > button:hover {
       color: #ffffff;
       background-color: gray;
       border-color: #e6f20c;
       border-radius: 10px;
      }

      #closebutton {
        position: absolute;
        margin-left: 10px;
        top: 0;
        font-size: 36px;
        color:  #000000;
        border: none;
        padding: 0;
        margin-top: 4px;
        background-color: transparent;
        cursor: pointer;
        
      }

      #closebutton :hover {
       background: none;
        
      }
      


    .openbtn {
        font-size: 20px;
        cursor: pointer;
        background-color: #111;
        color: white;
        padding: 10px 15px;
        border: none;
      }
    
    .openbtn  :hover {
        color: #b30000;
        background-color: #b30000;
      }


    

    
    .parameter{
        height: 7vh;
        width: 100%;
        border-radius: 7px;
        margin-top: 0.25rem;
        display: inline-flex;
        padding: 5px;
        
    }

    .parameter > .label2 > label{
        /* border: 1px solid black; */
        display:inline;
        
    }


    .parameter_ul{
        width: 80%;
        height: 100%;
        /* border: 1px solid black; */
        /* display: contents; */
        
    }


    .parameter_ul>ul{
        margin: 0px;
        padding: 0.5%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        /* border: 1px solid black; */
        /* margin-top: -1.2rem; */
        /* justify-content: space-evenly; */
        /* margin-left: 20%; */
    } 

    .parameter_ul > ul > li{
        margin: 0px;
        /* border: 1px solid blue; */
    }

    #humid{
        background-color: rgba(107, 119, 227, 0.8);
    }
    
    
    #temp{
        background-color: rgba(222, 60, 99,0.8); 
    }

    
    #lux{
        background-color: rgba(55, 203, 149,0.8);
    }

    
    #motion1{
        background-color: #0df49fcc;
    }
   

    #battery{
        background-color: rgba(255, 255, 0, 0.4);
    }
    

    #voltage{
        background-color: rgba(255, 255, 0, 0.4);
    }


    #pressure{
        background-color: rgba(145, 200, 228, 1);
    }
    

    #gx{
        background-color: rgba(168, 161, 150, 1);
    }
    

    #gy{
        background-color: rgba(255, 217, 192, 1);
    }
    
    
    #gz{
        background-color: rgba(239, 98, 98, 1);
    }
    

    #err{
        background-color: rgba(160, 191, 224, 1);
    }
    
    

    #imei{
        background-color: rgba(203, 255, 169, 1);
    }
    

    #csq{
        background-color: rgba(79, 192, 208, 1);
    }
    

    #fwv{
        background-color: rgba(255, 137, 137, 1);
    }


    #rr{
        background-color: rgba(255, 33, 113, 1);
    }

    #distance_travelled{
        background-color: rgba(168, 161, 150, 1);
    }

    #offline-humidity{
        background-color: rgba(107, 119, 227, 0.349);
    }
    
    #offline-temperature{
        background-color: rgba(222, 60, 98, 0.349);
    }

    #offline-lux{
        background-color: rgba(55, 203, 149,0.349);
    }

    #offline-motion{
        background-color: rgba(13, 244, 159, 0.378);
    }

    #offline-voltage{
        background-color: rgba(255, 255, 0, 0.133);
    }

    #offline-pressure{
        background-color: rgba(145, 200, 228, 0.511);
    }

    #offline-gx{
        background-color: rgba(168, 161, 150, 0.624);
    }

    #offline-gy{
        background-color: rgba(255, 217, 192, 0.352);
    }

    #offline-gz{
        background-color: rgba(239, 98, 98, 0.534);
    }

    #offline-err{
        background-color: rgba(160, 191, 224, 0.438);
    }
    
    #offline-csq{
        background-color: rgba(79, 193, 208, 0.487);
    }

    #offline-rr{
        background-color: rgba(255, 33, 114, 0.655);
    }

    #offline-gsmerr{
        background-color: rgba(159, 104, 59, 0.655);
    }

 

    
    .footer{
        height: 7vh;
        width: 100vw;
        border-top: 1px solid black;
        display: flex;
        justify-content: space-around;
        align-items: center;
        
        /* margin-top: 2% ; */
        
    }
    
    /* .stat{
        margin-top: 0.5rem;
    }
    
    .route{
        margin-top: 0.25rem;
    } */

    li {
        font-size: 12px;
        font-weight: 500;
        /* text-align: left; */
        margin-left: 19%;
        
    }

    ul {
        text-align: center;
    }

    .label1{
        
        /* width:140px; */
        margin-right:199px;
        
        
    }

    .label2{
        width: 20%;
        height: 100%;
        
        /* overflow-wrap: break-word; */
        /* word-wrap: break-word; */
        
        /* margin-right: 70%; */
        word-break: break-all;
        -ms-word-break: break-all;
        /* padding-right: 10px; */
        /* text-align: center; */
        hyphens:auto;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        display: inline-block;
        /* border: 1px solid black; */
    }

    #resetbutton{
              /* display: flex;   */
              /* justify-content: flex-start; */
              /* display: inline-block; */
              margin-right: 2%;

              
    }
    .button2{
        background-color: white; /* Green */
        border: 2px solid #4CAF50;
        color: black;
        padding: 9px 25px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 8px;
        margin: 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
    }

    .button2:hover {
        background-color: #4CAF50;
        color: white;
    }

    .button3{
        background-color: white; /* Green */
        border: 2px solid #008CBA;
        color: black;
        padding: 9px 25px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 8px;
        margin: 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
    }

    .button3:hover {
        background-color: #008CBA;
        color: white;
    }

    .button4{
        background-color: white; /* Green */
        border: 2px solid #f44336;
        color: black;
        padding: 9px 25px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 8px;
        margin: 4px 2px 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
    }

    .button4:hover {
        background-color: #f44336;
        color: white;
    }

  


    .version{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 2vh;
        width: 100vw;
       
    }
    .version>h6{
        margin-right: 0.3%;
    }


    /* sizing of map container */

    .map_page_main_div{
        height: 86vh;
        width: 100vw;
    }

    .leaflet-container{
        height: 86vh;
        width: 100%;
        
    }

    /* .markercluster-map { height: 90vh; } */


    #customdates{
        /* border: 1px solid black; */
        margin-left: 2%;
    }


    input[type="date"]{
        background-color: #0080ff;
        padding: 5px;
        /* position: absolute; */
        font-family: "Roboto Mono", monospace;
        color: white;
        font-size: 8px;
        border-radius: 5px;
        margin: 2px 2px 2px 2px;
    }

    ::-webkit-calendar-picker-indicator{
        background-color: white;
        padding: 5px;
        cursor: pointer;
        border-radius: 3px;
    }

    .button-dates{
           display: inline-flex;
           width: 100vw;
           justify-content: space-between;
           margin-top: 0.5%;
    }
    

    @media print{@page {size: landscape;}}
   

    /* .mapid{
        border: 1px solid black;
        
    } */

    /* } */


    #routedata{
        height: 40%;
        margin-top: 25%;
        
    }

    /* #mapfooter{
        
    } */



    

    




    .csvlink-btn{
        background-color: #ffffff;
        border: none;
        cursor: pointer;
    }

    /* .btnContainer{
        
    } */



    .overlay{
        
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(189,189,189,0.9);
        
    }

    .modalContainer{
        position: fixed;
        max-width: 25%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: #ffffff;
        /* height: 40%;  */
        box-shadow: 2.5px 2.5px 10px 1px;
        border-radius: 20px 20px 20px 20px;
        display: flex;
        /* justify-content: flex-end; */
        /* align-items: flex-start; */
        flex-direction: column;
    }

    .btn-print-graph{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #008CBA;
        color: black;
        padding: 10%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 10%;
        /* margin-left: 19%; */
        margin-bottom: 5%;
        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
        
    }

    .btn-print-graph:hover{
        background-color: #008CBA;
        color: white;
        border-radius: 20px 20px 20px 20px;
        
        
    }

    .btn-csv{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #4CAF50;
        color: black;
        padding: 5%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 14%;
        
        /* margin-left: 19%; */

        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
    }

    .btn-csv:hover{
        background-color: #4CAF50;
        color: white;
        border-radius: 20px 20px 20px 20px;
    }

    .btn-offline-csv{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #d60e0e;
        color: black;
        padding: 5%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 14%;
        
        /* margin-left: 19%; */

        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
    }

    .btn-offline-csv:hover{
        background-color: #d60e0e;
        color: white;
        border-radius: 20px 20px 20px 20px;
    }

    .closing-btn{
            margin-right: 4%;
            margin-top: 2.5%;
            border: none;
            background-color: #ffffff;
            font-size: 20px;
            cursor: pointer;
            align-self: flex-end;
    }

    .scheduled_date-btn{
        background-color: #000000;
        padding: 5px;
        /* position: absolute; */
        font-family: "Roboto Mono", monospace;
        color: white;
        font-size: 8px;
        border-radius: 5px;
        margin: 2px;
        transition: 0.4s;
    }

    .scheduled_date-btn:hover{
        color: black;
        background-color: white;
    }


    .scrollable_div{
        
        margin-left: 0.5%;
        margin-right: 0.5%;
        margin-bottom: 0.5%;
        padding:0px 5px 5px 5px;
        height: 40vh;
        width: 99vw;
        overflow-y: scroll;
        /* border: 2px solid black; */
        border-radius: 5px;
        
    }

    .scrollable_div::-webkit-scrollbar {
        width: 5px;
    }

    .scrollable_div::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
    }

    .scrollable_div::-webkit-scrollbar-thumb {
        background: black; 
        border-radius: 10px;
    } 

    .scrollable_div::-webkit-scrollbar-thumb:hover {
        background: #b30000; 
    }  

    .component-modal-body{
        height: 25vh;
        width: 99vw;
        border: 2px solid black;
        background-color: #fff;
        border-radius: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .closing-modal-btn{
        width: 45px;
        height: 40px;
        background-color: #000;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        font-size: 150%;
        position:absolute;
        /* padding:  7px 5px 5px 17px; */
        margin-left: -0.445%;
        margin-top: -0.445%;
        cursor: pointer;
        
    }

    .datapoint_count{
        height: 10px;
        width: 10px;
        background-color: #000;
        color: #fff;
    }


}

@media screen  and (min-width:600px) and (orientation : landscape){
    html {
        box-sizing: border-box;
      }
      *, *:before, *:after {
        box-sizing: inherit;
      }
   
    .header{
        height: 7vh;
        width: 100vw;
        border-bottom: 1px solid black;
        display: flex;
        justify-content: space-between;
        /* padding: 0.5%; */
        /* align-items: flex-start; */
        overflow:hidden;
    }
    
    
    .icon{
        align-self: center;
        
    }

    .react-icons{
        /* vertical-align: middle; */
        font-size: 200%;
        
    }
    
    .mac-address {
        /* align-self: center; */
        /* margin-left: 40vw; */
        font-size: 100%;
        /* border-right: 40px; */
        /* padding-top: 2.5%; */
        /* padding-bottom: 1.5%; */
        margin: auto;
        /* padding-bottom: 5%; */
    }

    .mac-address h3{
        margin: auto;
    }

    .graph{
        width: 100vw;
        height: 43vh;
    }
    
    
    .graph1{
        height: 75%;
        width: 99vw;
        margin: auto;
        display: inline-flex;
    }

    .line-chart{
        height: 100%;
        width: 100%;

    }

    .graph-sidepanel-open-button{
        position: absolute;
        right: 0px;
        padding: 0;
        margin: 0;
    }

    .side-panel  {
        width:  0px;
        position: fixed;
        z-index: 1;
        height: 250px;
        top: 7vh;
        right: 0px;
        background-color: #111;
        /* overflow-x: hidden; */
        transition: 0.5s;
        padding-top: 60px;
        /* overflow-y: scroll; */
        
      }

      

      .side-panel > button {
        /* padding: 8px 8px 8px 32px; */
        margin: auto;
        text-align: center;
        text-decoration: none;
        font-size: 25px;
        font-family:Verdana, Geneva, Tahoma, sans-serif;
        color: #818181;
        display: block;
        transition: 0.3s;
        cursor: pointer;
        /* border-radius: 10px; */
        background-color: transparent;
        border: none;
      }

      .side-panel > button:hover {
       color: #ffffff;
       background-color: gray;
       border-color: #e6f20c;
       border-radius: 10px;
      }

      #closebutton {
        position: absolute;
        margin-left: 10px;
        top: 0;
        font-size: 36px;
        color:  #000000;
        border: none;
        padding: 0;
        margin-top: 4px;
        background-color: transparent;
        cursor: pointer;
        
      }

      #closebutton :hover {
       background: none;
        
      }
      


    .openbtn {
        font-size: 20px;
        cursor: pointer;
        background-color: #111;
        color: white;
        padding: 10px 15px;
        border: none;
      }
    
    .openbtn  :hover {
        color: #b30000;
        background-color: #b30000;
      }



    
    .parameter{
        height: 7vh;
        width: 100%;
        border-radius: 7px;
        margin-top: 0.25rem;
        display: inline-flex;
        padding: 5px;
        
    }

    .parameter > .label2 > label{
        /* border: 1px solid black; */
        display:inline;
        
    }


    .parameter_ul{
        width: 80%;
        height: 100%;
        /* border: 1px solid black; */
        /* display: contents; */
        
    }


    .parameter_ul>ul{
        margin: 0px;
        padding: 0.5%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        /* border: 1px solid black; */
        /* margin-top: -1.2rem; */
        /* justify-content: space-evenly; */
        /* margin-left: 20%; */
    } 

    .parameter_ul > ul > li{
        margin: 0px;
        /* border: 1px solid blue; */
    }


    #humid{
        background-color: rgba(107, 119, 227, 0.8);
    }
    
    
    #temp{
        background-color: rgba(222, 60, 99,0.8); 
    }

    
    #lux{
        background-color: rgba(55, 203, 149,0.8);
    }

    
    #motion1{
        background-color: rgba(13, 244, 159, 0.801);
    }
   

    #battery{
        background-color: rgba(255, 255, 0, 0.4);
    }
    

    #voltage{
        background-color: rgba(255, 255, 0, 0.4);
    }


    #pressure{
        background-color: rgba(145, 200, 228, 1);
    }
    

    #gx{
        background-color: rgba(168, 161, 150, 1);
    }
    

    #gy{
        background-color: rgba(255, 217, 192, 1);
    }
    
    
    #gz{
        background-color: rgba(239, 98, 98, 1);
    }
    

    #err{
        background-color: rgba(160, 191, 224, 1);
    }
    
    

    #imei{
        background-color: rgba(203, 255, 169, 1);
    }
    

    #csq{
        background-color: rgba(79, 192, 208, 1);
    }
    

    #fwv{
        background-color: rgba(255, 137, 137, 1);
    }


    #rr{
        background-color: rgba(255, 33, 113, 1);
    }

    #distance_travelled{
        background-color: rgba(168, 161, 150, 1);
    }

    #offline-humidity{
        background-color: rgba(107, 119, 227, 0.349);
    }
    
    #offline-temperature{
        background-color: rgba(222, 60, 98, 0.349);
    }

    #offline-lux{
        background-color: rgba(55, 203, 149,0.349);
    }

    #offline-motion{
        background-color: rgba(13, 244, 159, 0.378);
    }

    #offline-voltage{
        background-color: rgba(255, 255, 0, 0.133);
    }

    #offline-pressure{
        background-color: rgba(145, 200, 228, 0.511);
    }

    #offline-gx{
        background-color: rgba(168, 161, 150, 0.624);
    }

    #offline-gy{
        background-color: rgba(255, 217, 192, 0.352);
    }

    #offline-gz{
        background-color: rgba(239, 98, 98, 0.534);
    }

    #offline-err{
        background-color: rgba(160, 191, 224, 0.438);
    }
    
    #offline-csq{
        background-color: rgba(79, 193, 208, 0.487);
    }

    #offline-rr{
        background-color: rgba(255, 33, 114, 0.655);
    }

    #offline-gsmerr{
        background-color: rgba(159, 104, 59, 0.655);
    }

    


    
    .footer{
        height: 7vh;
        width: 100vw;
        border-top: 1px solid black;
        display: flex;
        justify-content: space-around;
        align-items: center;
        
        /* margin-top: 2% ; */
        
    }
    
    /* .stat{
        margin-top: 0.5rem;
    }
    
    .route{
        margin-top: 0.25rem;
    } */

    li {
        font-size: 12px;
        font-weight: 500;
        /* text-align: left; */
        margin-left: 19%;
        
    }

    ul {
        text-align: center;
    }

    .label1{
        
        /* width:140px; */
        margin-right:199px;
        
        
    }

    .label2{
        width: 20%;
        height: 100%;
        
        /* overflow-wrap: break-word; */
        /* word-wrap: break-word; */
        
        /* margin-right: 70%; */
        word-break: break-all;
        -ms-word-break: break-all;
        /* padding-right: 10px; */
        /* text-align: center; */
        hyphens:auto;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        display: inline-block;
        /* border: 1px solid black; */
    }

    #resetbutton{
              /* display: flex;   */
              /* justify-content: flex-start; */
              /* display: inline-block; */
              margin-right: 2%;

              
    }
    .button2{
        background-color: white; /* Green */
        border: 2px solid #4CAF50;
        color: black;
        padding: 9px 25px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 8px;
        margin: 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
    }

    .button2:hover {
        background-color: #4CAF50;
        color: white;
    }

    .button3{
        background-color: white; /* Green */
        border: 2px solid #008CBA;
        color: black;
        padding: 9px 25px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 8px;
        margin: 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
    }

    .button3:hover {
        background-color: #008CBA;
        color: white;
    }

    .button4{
        background-color: white; /* Green */
        border: 2px solid #f44336;
        color: black;
        padding: 9px 25px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 8px;
        margin: 4px 2px 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
    }

    .button4:hover {
        background-color: #f44336;
        color: white;
    }

  


    .version{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 2vh;
        width: 100vw;
       
    }
    .version>h6{
        margin-right: 0.3%;
    }


    /* sizing of map container */

    .map_page_main_div{
        height: 86vh;
        width: 100vw;
    }

    .leaflet-container{
        height: 86vh;
        width: 100%;
        
    }

    /* .markercluster-map { height: 90vh; } */


    #customdates{
        /* border: 1px solid black; */
        margin-left: 2%;
    }


    input[type="date"]{
        background-color: #0080ff;
        padding: 5px;
        /* position: absolute; */
        font-family: "Roboto Mono", monospace;
        color: white;
        font-size: 8px;
        border-radius: 5px;
        margin: 2px 2px 2px 2px;
    }

    ::-webkit-calendar-picker-indicator{
        background-color: white;
        padding: 5px;
        cursor: pointer;
        border-radius: 3px;
    }

    .button-dates{
           display: inline-flex;
           width: 100vw;
           height: 25%;
           justify-content: space-between;
           margin-top: 0.5%;
    }
    

    @media print{@page {size: landscape;}}
   

    /* .mapid{
        border: 1px solid black;
        
    } */

    /* } */


    #routedata{
        height: 40%;
        margin-top: 25%;
        
    }

    /* #mapfooter{
        
    } */



    

    




    .csvlink-btn{
        background-color: #ffffff;
        border: none;
        cursor: pointer;
    }

    /* .btnContainer{
        
    } */



    .overlay{
        
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(189,189,189,0.9);
        
    }

    .modalContainer{
        position: fixed;
        max-width: 25%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: #ffffff;
        /* height: 40%;  */
        box-shadow: 2.5px 2.5px 10px 1px;
        border-radius: 20px 20px 20px 20px;
        display: flex;
        /* justify-content: flex-end; */
        /* align-items: flex-start; */
        flex-direction: column;
    }

    .btn-print-graph{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #008CBA;
        color: black;
        padding: 10%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 10%;
        /* margin-left: 19%; */
        margin-bottom: 5%;
        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
        
    }

    .btn-print-graph:hover{
        background-color: #008CBA;
        color: white;
        border-radius: 20px 20px 20px 20px;
        
        
    }

    .btn-csv{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #4CAF50;
        color: black;
        padding: 5%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 14%;
        
        /* margin-left: 19%; */

        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
    }

    .btn-csv:hover{
        background-color: #4CAF50;
        color: white;
        border-radius: 20px 20px 20px 20px;
    }

    .btn-offline-csv{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #d60e0e;
        color: black;
        padding: 5%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 14%;
        
        /* margin-left: 19%; */

        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
    }

    .btn-offline-csv:hover{
        background-color: #d60e0e;
        color: white;
        border-radius: 20px 20px 20px 20px;
    }

    .closing-btn{
            margin-right: 4%;
            margin-top: 2.5%;
            border: none;
            background-color: #ffffff;
            font-size: 20px;
            cursor: pointer;
            align-self: flex-end;
    }

    .scheduled_date-btn{
        background-color: #000000;
        padding: 5px;
        /* position: absolute; */
        font-family: "Roboto Mono", monospace;
        color: white;
        font-size: 8px;
        border-radius: 5px;
        margin: 2px;
        transition: 0.4s;
    }

    .scheduled_date-btn:hover{
        color: black;
        background-color: white;
    }


    .scrollable_div{
        
        margin-left: 0.5%;
        margin-right: 0.5%;
        margin-bottom: 0.5%;
        padding:0px 5px 5px 5px;
        height: 40vh;
        width: 99vw;
        overflow-y: scroll;
        /* border: 2px solid black; */
        border-radius: 5px;
        
    }

    .scrollable_div::-webkit-scrollbar {
        width: 5px;
    }

    .scrollable_div::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
    }

    .scrollable_div::-webkit-scrollbar-thumb {
        background: black; 
        border-radius: 10px;
    } 

    .scrollable_div::-webkit-scrollbar-thumb:hover {
        background: #b30000; 
    }  

    .component-modal-body{
        height: 25vh;
        width: 99vw;
        border: 2px solid black;
        background-color: #fff;
        border-radius: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .closing-modal-btn{
        width: 45px;
        height: 40px;
        background-color: #000;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        font-size: 150%;
        position:absolute;
        /* padding:  7px 5px 5px 17px; */
        margin-left: -0.445%;
        margin-top: -0.445%;
        cursor: pointer;
        
    }

    .datapoint_count{
        height: 10px;
        width: 10px;
        background-color: #000;
        color: #fff;
    }


}



@media screen  and (min-width:900px) and (orientation : portrait){
    html {
        box-sizing: border-box;
      }
      *, *:before, *:after {
        box-sizing: inherit;
      }
   
    .header{
        height: 7vh;
        width: 100vw;
        border-bottom: 1px solid black;
        /* border-top: 1px solid black; */
        display: flex;
        justify-content: space-between;
        /* padding: 0.5%; */
        /* align-items: flex-start; */
        overflow:hidden;
    }
    
    
    .icon{
        align-self: center;
        
    }

    .react-icons{
        /* vertical-align: middle; */
        font-size: 200%;
        
    }
    
    .mac-address {
        /* align-self: center; */
        /* margin-left: 40vw; */
        font-size: 100%;
        /* border-right: 40px; */
        /* padding-top: 2.5%; */
        /* padding-bottom: 1.5%; */
        margin: auto;
        /* padding-bottom: 5%; */
    }

    .mac-address h3{
        margin: auto;
    }
    
    .graph{
        width: 100vw;
        height: 43vh;
    }
    
    .graph1{
        height: 35vh;
        width: 99vw;
        margin: auto;
        display: inline-flex;

        /* background-color: gray; */
        /* margin-top: 1rem; */
        /* margin-bottom: 1%; */
        /* margin-left: 3px; */
        /* border-radius: 5px; */

    }

    .line-chart{
        height: 100%;
        width: 100%;

    }

    .graph-sidepanel-open-button{
        position: absolute;
        right: 0px;
        padding: 0;
        margin: 0;
    }

    .side-panel  {
        width:  0px;
        position: fixed;
        z-index: 1;
        height: 250px;
        top: 7vh;
        right: 0px;
        background-color: #111;
        /* overflow-x: hidden; */
        transition: 0.5s;
        padding-top: 60px;
        /* overflow-y: scroll; */
        
      }

      

      .side-panel > button {
        /* padding: 8px 8px 8px 32px; */
        margin: auto;
        text-align: center;
        text-decoration: none;
        font-size: 25px;
        font-family:Verdana, Geneva, Tahoma, sans-serif;
        color: #818181;
        display: block;
        transition: 0.3s;
        cursor: pointer;
        /* border-radius: 10px; */
        background-color: transparent;
        border: none;
      }

      .side-panel > button:hover {
       color: #ffffff;
       background-color: gray;
       border-color: #e6f20c;
       border-radius: 10px;
      }

      #closebutton {
        position: absolute;
        margin-left: 10px;
        top: 0;
        font-size: 36px;
        color:  #000000;
        border: none;
        padding: 0;
        margin-top: 4px;
        background-color: transparent;
        cursor: pointer;
        
      }

      #closebutton :hover {
       background: none;
        
      }
      


    .openbtn {
        font-size: 20px;
        cursor: pointer;
        background-color: #111;
        color: white;
        padding: 10px 15px;
        border: none;
      }
    
    .openbtn  :hover {
        color: #b30000;
        background-color: #b30000;
      }


    

    
    .parameter{
        height: 7vh;
        width: 100%;
        border-radius: 7px;
        margin-top: 0.25rem;
        display: inline-flex;
        padding: 5px;
        
    }

    .parameter > .label2 > label{
        /* border: 1px solid black; */
        display:inline;
        
    }


    .parameter_ul{
        width: 80%;
        height: 100%;
        /* border: 1px solid black; */
        /* display: contents; */
        
    }


    .parameter_ul>ul{
        margin: 0px;
        padding: 0.5%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        /* border: 1px solid black; */
        /* margin-top: -1.2rem; */
        /* justify-content: space-evenly; */
        /* margin-left: 20%; */
    } 

    .parameter_ul > ul > li{
        margin: 0px;
        /* border: 1px solid blue; */
    }

    
    #humid{
        background-color: rgba(107, 119, 227, 0.8);
    }
    
    
    #temp{
        background-color: rgba(222, 60, 99,0.8); 
    }

    
    #lux{
        background-color: rgba(55, 203, 149,0.8);
    }

    
    #motion1{
        background-color: rgba(13, 244, 159, 0.801);
    }
   

    #battery{
        background-color: rgba(255, 255, 0, 0.4);
    }
    

    #voltage{
        background-color: rgba(255, 255, 0, 0.4);
    }


    #pressure{
        background-color: rgba(145, 200, 228, 1);
    }
    

    #gx{
        background-color: rgba(168, 161, 150, 1);
    }
    

    #gy{
        background-color: rgba(255, 217, 192, 1);
    }
    
    
    #gz{
        background-color: rgba(239, 98, 98, 1);
    }
    

    #err{
        background-color: rgba(160, 191, 224, 1);
    }
    
    

    #imei{
        background-color: rgba(203, 255, 169, 1);
    }
    

    #csq{
        background-color: rgba(79, 192, 208, 1);
    }
    

    #fwv{
        background-color: rgba(255, 137, 137, 1);
    }


    #rr{
        background-color: rgba(255, 33, 113, 1);
    }

    #distance_travelled{
        background-color: rgba(168, 161, 150, 1);
    }

    #offline-humidity{
        background-color: rgba(107, 119, 227, 0.349);
    }
    
    #offline-temperature{
        background-color: rgba(222, 60, 98, 0.349);
    }

    #offline-lux{
        background-color: rgba(55, 203, 149,0.349);
    }

    #offline-motion{
        background-color: rgba(13, 244, 159, 0.378);
    }

    #offline-voltage{
        background-color: rgba(255, 255, 0, 0.133);
    }

    #offline-pressure{
        background-color: rgba(145, 200, 228, 0.511);
    }

    #offline-gx{
        background-color: rgba(168, 161, 150, 0.624);
    }

    #offline-gy{
        background-color: rgba(255, 217, 192, 0.352);
    }

    #offline-gz{
        background-color: rgba(239, 98, 98, 0.534);
    }

    #offline-err{
        background-color: rgba(160, 191, 224, 0.438);
    }
    
    #offline-csq{
        background-color: rgba(79, 193, 208, 0.487);
    }

    #offline-rr{
        background-color: rgba(255, 33, 114, 0.655);
    }

    #offline-gsmerr{
        background-color: rgba(159, 104, 59, 0.655);
    }


    

    
    .footer{
        height: 7vh;
        width: 100vw;
        border-top: 1px solid black;
        display: flex;
        justify-content: space-around;
        align-items: center;
        
        /* margin-top: 2% ; */
        
    }
    
    /* .stat{
        margin-top: 0.5rem;
    }
    
    .route{
        margin-top: 0.25rem;
    } */

    li {
        font-size: 12px;
        font-weight: 500;
        /* text-align: left; */
        margin-left: 19%;
        
    }

    ul {
        text-align: center;
    }

    .label1{
        
        /* width:140px; */
        margin-right:199px;
        
        
    }

    .label2{
        width: 20%;
        height: 100%;
        
        /* overflow-wrap: break-word; */
        /* word-wrap: break-word; */
        
        /* margin-right: 70%; */
        word-break: break-all;
        -ms-word-break: break-all;
        /* padding-right: 10px; */
        /* text-align: center; */
        hyphens:auto;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        display: inline-block;
        /* border: 1px solid black; */
    }

    #resetbutton{
              /* display: flex;   */
              /* justify-content: flex-start; */
              /* display: inline-block; */
            margin-right: 2%;

              
    }
    .button2{
        background-color: white; /* Green */
        border: 2px solid #4CAF50;
        color: black;
        padding: 10px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 12px;
        margin: 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
    }

    .button2:hover {
        background-color: #4CAF50;
        color: white;
    }

    .button3{
        background-color: white; /* Green */
        border: 2px solid #008CBA;
        color: black;
        padding: 10px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 12px;
        margin: 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
    }

    .button3:hover {
        background-color: #008CBA;
        color: white;
    }

    .button4{
        background-color: white; /* Green */
        border: 2px solid #f44336;
        color: black;
        padding: 10px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 12px;
        margin: 4px 2px 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
    }

    .button4:hover {
        background-color: #f44336;
        color: white;
    }

  


    .version{
        
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100vw;
        height: 2vh;
       
    }
    .version>h6{
     	margin-right: 0.3%;
    }


    /* sizing of map container */

    .map_page_main_div{
        height: 86vh;
        width: 100vw;
    }

    .leaflet-container{
        height: 86vh;
        width: 100%;
        
    }

    /* .markercluster-map { height: 90vh; } */


    #customdates{
        /* border: 1px solid black; */
        margin-left: 2%;
    }
    



    input[type="date"]{
        background-color: #0080ff;
        padding: 8px;
        /* position: absolute; */
        font-family: "Roboto Mono", monospace;
        color: white;
        font-size: 12px;
        border-radius: 5px;
        margin: 2px 2px 2px 2px;
    }

    ::-webkit-calendar-picker-indicator{
        background-color: white;
        padding: 5px;
        cursor: pointer;
        border-radius: 3px;
    }

    .button-dates{
           display: inline-flex;
           width: 100vw;
           height: 6vh;
           justify-content: space-between;
           margin-top: 0.5%;
    }
    

    @media print{@page {size: landscape;}}
   

    /* .mapid{
        border: 1px solid black;
        
    } */

    /* } */


    #routedata{
        height: 40%;
        margin-top: 25%;
        
    }

    /* #mapfooter{
        
    } */

    




    .csvlink-btn{
        background-color: #ffffff;
        border: none;
        cursor: pointer;
    }

    /* .btnContainer{
        
    } */



    .overlay{
        
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(189,189,189,0.9);
        
    }

    .modalContainer{
        position: fixed;
        max-width: 25%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: #ffffff;
        /* height: 40%;  */
        box-shadow: 2.5px 2.5px 10px 1px;
        border-radius: 20px 20px 20px 20px;
        display: flex;
        /* justify-content: flex-end; */
        /* align-items: flex-start; */
        flex-direction: column;
    }

    .btn-print-graph{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #008CBA;
        color: black;
        padding: 10%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 10%;
        /* margin-left: 19%; */
        margin-bottom: 5%;
        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
        
    }

    .btn-print-graph:hover{
        background-color: #008CBA;
        color: white;
        border-radius: 20px 20px 20px 20px;
        
        
    }

    .btn-csv{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #4CAF50;
        color: black;
        padding: 5%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 14%;
        
        /* margin-left: 19%; */

        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
    }

    .btn-csv:hover{
        background-color: #4CAF50;
        color: white;
        border-radius: 20px 20px 20px 20px;
    }


    .btn-offline-csv{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #d60e0e;
        color: black;
        padding: 5%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 14%;
        
        /* margin-left: 19%; */

        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
    }

    .btn-offline-csv:hover{
        background-color: #d60e0e;
        color: white;
        border-radius: 20px 20px 20px 20px;
    }


    .closing-btn{
            margin-right: 4%;
            margin-top: 2.5%;
            border: none;
            background-color: #ffffff;
            font-size: 20px;
            cursor: pointer;
            align-self: flex-end;
    }

    .scheduled_date-btn{
        background-color: #000000;
        padding: 10px;
        /* position: absolute; */
        font-family: "Roboto Mono", monospace;
        color: white;
        font-size: 12px;
        border-radius: 5px;
        margin: 2px;
        transition: 0.4s;
    }

    .scheduled_date-btn:hover{
        color: black;
        background-color: white;
    }


    .scrollable_div{
        
        margin-left: 0.5%;
        margin-right: 0.5%;
        margin-bottom: 0.5%;
        padding:0px 5px 5px 5px;
        height: 40vh;
        width: 99vw;
        overflow-y: scroll;
        /* border: 2px solid black; */
        border-radius: 5px;
        
    }

    .scrollable_div::-webkit-scrollbar {
        width: 5px;
    }

    .scrollable_div::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
    }

    .scrollable_div::-webkit-scrollbar-thumb {
        background: black; 
        border-radius: 10px;
    } 

    .scrollable_div::-webkit-scrollbar-thumb:hover {
        background: #b30000; 
    }  

    .component-modal-body{
        height: 25vh;
        width: 99vw;
        border: 2px solid black;
        background-color: #fff;
        border-radius: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .closing-modal-btn{
        width: 45px;
        height: 40px;
        background-color: #000;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        font-size: 150%;
        position:absolute;
        /* padding:  7px 5px 5px 17px; */
        margin-left: -0.445%;
        margin-top: -0.445%;
        cursor: pointer;
        
    }

    .datapoint_count{
        height: 10px;
        width: 10px;
        background-color: #000;
        color: #fff;
    }


}

@media screen  and (min-width:900px) and (orientation : landscape){
    html {
        box-sizing: border-box;
      }
      *, *:before, *:after {
        box-sizing: inherit;
      }
   
    .header{
        height: 7vh;
        width: 100vw;
        border-bottom: 1px solid black;
        /* border-top: 1px solid black; */
        display: flex;
        justify-content: space-between;
        /* padding: 0.5%; */
        /* align-items: flex-start; */
        overflow:hidden;
    }
    
    
    .icon{
        align-self: center;
        
    }

    .react-icons{
        /* vertical-align: middle; */
        font-size: 200%;
        
    }
    
    .mac-address {
        /* align-self: center; */
        /* margin-left: 40vw; */
        font-size: 100%;
        /* border-right: 40px; */
        /* padding-top: 2.5%; */
        /* padding-bottom: 1.5%; */
        margin: auto;
        /* padding-bottom: 5%; */
    }

    .mac-address h3{
        margin: auto;
    }
    
    .graph{
        width: 100vw;
        height: 43vh;
    }
    
    .graph1{
        height: 75%;
        width: 100vw;
        margin: auto;
        display: inline-flex;
        /* position: relative; */
        
        /* background-color: gray; */
        /* margin-top: 1rem; */
        /* margin-bottom: 1%; */
        /* margin-left: 3px; */
        /* border-radius: 5px; */

    }
    
    .line-chart{
        height: 100%;
        width: 100%;

    }

    .graph-sidepanel-open-button{
        position: absolute;
        right: 0px;
        padding: 0;
        margin: 0;
    }

    .side-panel  {
        width:  0px;
        position: fixed;
        z-index: 1;
        height: 250px;
        top: 7vh;
        right: 0px;
        background-color: #111;
        /* overflow-x: hidden; */
        transition: 0.5s;
        padding-top: 60px;
        /* overflow-y: scroll; */
        
      }

      

      .side-panel > button {
        /* padding: 8px 8px 8px 32px; */
        margin: auto;
        text-align: center;
        text-decoration: none;
        font-size: 25px;
        font-family:Verdana, Geneva, Tahoma, sans-serif;
        color: #818181;
        display: block;
        transition: 0.3s;
        cursor: pointer;
        /* border-radius: 10px; */
        background-color: transparent;
        border: none;
      }

      .side-panel > button:hover {
       color: #ffffff;
       background-color: gray;
       border-color: #e6f20c;
       border-radius: 10px;
      }

      #closebutton {
        position: absolute;
        margin-left: 10px;
        top: 0;
        font-size: 36px;
        color:  #000000;
        border: none;
        padding: 0;
        margin-top: 4px;
        background-color: transparent;
        cursor: pointer;
        
      }

      #closebutton :hover {
       background: none;
        
      }
      


    .openbtn {
        font-size: 20px;
        cursor: pointer;
        background-color: #111;
        color: white;
        padding: 10px 15px;
        border: none;
      }
    
    .openbtn  :hover {
        color: #b30000;
        background-color: #b30000;
      }

    
    .parameter{
        height: 7vh;
        width: 100%;
        border-radius: 7px;
        margin-top: 0.25rem;
        display: inline-flex;
        padding: 5px;
        
    }

    .parameter > .label2 > label{
        /* border: 1px solid black; */
        display:inline;
        
    }


    .parameter_ul{
        width: 80%;
        height: 100%;
        /* border: 1px solid black; */
        /* display: contents; */
        
    }


    .parameter_ul>ul{
        margin: 0px;
        padding: 0.5%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        /* border: 1px solid black; */
        /* margin-top: -1.2rem; */
        /* justify-content: space-evenly; */
        /* margin-left: 20%; */
    } 

    .parameter_ul > ul > li{
        margin: 0px;
        /* border: 1px solid blue; */
    }

    #humid{
        background-color: rgba(107, 119, 227, 0.8);
    }
    
    
    #temp{
        background-color: rgba(222, 60, 99,0.8); 
    }

    
    #lux{
        background-color: rgba(55, 203, 149,0.8);
    }

    
    #motion1{
        background-color: rgba(13, 244, 159, 0.801);
    }
   

    #battery{
        background-color: rgba(255, 255, 0, 0.4);
    }
    

    #voltage{
        background-color: rgba(255, 255, 0, 0.4);
    }


    #pressure{
        background-color: rgba(145, 200, 228, 1);
    }
    

    #gx{
        background-color: rgba(168, 161, 150, 1);
    }
    

    #gy{
        background-color: rgba(255, 217, 192, 1);
    }
    
    
    #gz{
        background-color: rgba(239, 98, 98, 1);
    }
    

    #err{
        background-color: rgba(160, 191, 224, 1);
    }
    
    

    #imei{
        background-color: rgba(203, 255, 169, 1);
    }
    

    #csq{
        background-color: rgba(79, 192, 208, 1);
    }
    

    #fwv{
        background-color: rgba(255, 137, 137, 1);
    }


    #rr{
        background-color: rgba(255, 33, 113, 1);
    }

    #distance_travelled{
        background-color: rgba(168, 161, 150, 1);
    }

    #offline-humidity{
        background-color: rgba(107, 119, 227, 0.349);
    }
    
    #offline-temperature{
        background-color: rgba(222, 60, 98, 0.349);
    }

    #offline-lux{
        background-color: rgba(55, 203, 149,0.349);
    }

    #offline-motion{
        background-color: rgba(13, 244, 159, 0.378);
    }

    #offline-voltage{
        background-color: rgba(255, 255, 0, 0.133);
    }

    #offline-pressure{
        background-color: rgba(145, 200, 228, 0.511);
    }

    #offline-gx{
        background-color: rgba(168, 161, 150, 0.624);
    }

    #offline-gy{
        background-color: rgba(255, 217, 192, 0.352);
    }

    #offline-gz{
        background-color: rgba(239, 98, 98, 0.534);
    }

    #offline-err{
        background-color: rgba(160, 191, 224, 0.438);
    }
    
    #offline-csq{
        background-color: rgba(79, 193, 208, 0.487);
    }

    #offline-rr{
        background-color: rgba(255, 33, 114, 0.655);
    }

    #offline-gsmerr{
        background-color: rgba(159, 104, 59, 0.655);
    }


    
    .footer{
        height: 7vh;
        width: 100vw;
        border-top: 1px solid black;
        display: flex;
        justify-content: space-around;
        align-items: center;
        
        /* margin-top: 2% ; */
        
    }
    
    /* .stat{
        margin-top: 0.5rem;
    }
    
    .route{
        margin-top: 0.25rem;
    } */

    li {
        font-size: 12px;
        font-weight: 500;
        /* text-align: left; */
        margin-left: 19%;
        
    }

    ul {
        text-align: center;
    }

    .label1{
        
        /* width:140px; */
        margin-right:199px;
        
        
    }

    .label2{
        width: 20%;
        height: 100%;
        
        /* overflow-wrap: break-word; */
        /* word-wrap: break-word; */
        
        /* margin-right: 70%; */
        word-break: break-all;
        -ms-word-break: break-all;
        /* padding-right: 10px; */
        /* text-align: center; */
        hyphens:auto;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        display: inline-block;
        /* border: 1px solid black; */
    }

    #resetbutton{
              /* display: flex;   */
              /* justify-content: flex-start; */
              /* display: inline-block; */
            margin-right: 2%;
            height: 90%;

              
    }
    .button2{
        background-color: white; /* Green */
        border: 2px solid #4CAF50;
        color: black;
        padding: 5px 16px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 12px;
        margin: 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
        height: 90%;
        
    }

    .button2:hover {
        background-color: #4CAF50;
        color: white;
    }

    .button3{
        background-color: white; /* Green */
        border: 2px solid #008CBA;
        color: black;
        padding: 5px 16px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 12px;
        margin: 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
        height: 90%;
    }

    .button3:hover {
        background-color: #008CBA;
        color: white;
    }

    .button4{
        background-color: white; /* Green */
        border: 2px solid #f44336;
        color: black;
        padding: 5px 16px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 12px;
        margin: 4px 2px 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
        height: 90%;
    }

    .button4:hover {
        background-color: #f44336;
        color: white;
    }

  


    .version{
        
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100vw;
        height: 2vh;
       
    }
    .version>h6{
     	margin-right: 0.3%;
    }


    /* sizing of map container */

    .map_page_main_div{
        height: 86vh;
        width: 100vw;
    }

    .leaflet-container{
        height: 86vh;
        width: 100%;
        
    }

    /* .markercluster-map { height: 90vh; } */


    #customdates{
        height: 90%;
        /* border: 1px solid black; */
        margin-left: 2%;
    }
    



    input[type="date"]{
        background-color: #0080ff;
        padding: 8px;
        /* position: absolute; */
        font-family: "Roboto Mono", monospace;
        color: white;
        font-size: 12px;
        border-radius: 5px;
        margin: 2px 2px 2px 2px;
        height: 90%;
    }

    ::-webkit-calendar-picker-indicator{
        background-color: white;
        padding: 5px;
        cursor: pointer;
        border-radius: 3px;
    }

    .button-dates{
           display: inline-flex;
           width: 100vw;
           height: 25%;
           justify-content: space-between;
           margin-top: 0.5%;
    }
    

    @media print{@page {size: landscape;}}
   

    /* .mapid{
        border: 1px solid black;
        
    } */

    /* } */


    #routedata{
        height: 40%;
        margin-top: 25%;
        
    }

    /* #mapfooter{
        
    } */

    




    .csvlink-btn{
        background-color: #ffffff;
        border: none;
        cursor: pointer;
    }

    /* .btnContainer{
        
    } */



    .overlay{
        
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(189,189,189,0.9);
        
    }

    .modalContainer{
        position: fixed;
        max-width: 25%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: #ffffff;
        /* height: 40%;  */
        box-shadow: 2.5px 2.5px 10px 1px;
        border-radius: 20px 20px 20px 20px;
        display: flex;
        /* justify-content: flex-end; */
        /* align-items: flex-start; */
        flex-direction: column;
    }

    .btn-print-graph{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #008CBA;
        color: black;
        padding: 10%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 10%;
        /* margin-left: 19%; */
        margin-bottom: 5%;
        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
        
    }

    .btn-print-graph:hover{
        background-color: #008CBA;
        color: white;
        border-radius: 20px 20px 20px 20px;
        
        
    }

    .btn-csv{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #4CAF50;
        color: black;
        padding: 5%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 14%;
        
        /* margin-left: 19%; */

        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
    }

    .btn-csv:hover{
        background-color: #4CAF50;
        color: white;
        border-radius: 20px 20px 20px 20px;
    }

    .btn-offline-csv{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #d60e0e;
        color: black;
        padding: 5%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 14%;
        
        /* margin-left: 19%; */

        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
    }

    .btn-offline-csv:hover{
        background-color: #d60e0e;
        color: white;
        border-radius: 20px 20px 20px 20px;
    }

    .closing-btn{
            margin-right: 4%;
            margin-top: 2.5%;
            border: none;
            background-color: #ffffff;
            font-size: 20px;
            cursor: pointer;
            align-self: flex-end;
    }

    .scheduled_date-btn{
        background-color: #000000;
        padding: 8px;
        /* position: absolute; */
        font-family: "Roboto Mono", monospace;
        color: white;
        font-size: 12px;
        border-radius: 5px;
        margin: 2px;
        transition: 0.4s;
        height: 90%;
    }

    .scheduled_date-btn:hover{
        color: black;
        background-color: white;
    }


    .scrollable_div{
        
        margin-left: 0.5%;
        margin-right: 0.5%;
        margin-bottom: 0.5%;
        padding:0px 5px 5px 5px;
        height: 40vh;
        width: 99vw;
        overflow-y: scroll;
        /* border: 2px solid black; */
        border-radius: 5px;
        
    }

    .scrollable_div::-webkit-scrollbar {
        width: 5px;
    }

    .scrollable_div::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
    }

    .scrollable_div::-webkit-scrollbar-thumb {
        background: black; 
        border-radius: 10px;
    } 

    .scrollable_div::-webkit-scrollbar-thumb:hover {
        background: #b30000; 
    }  

    .component-modal-body{
        height: 25vh;
        width: 99vw;
        border: 2px solid black;
        background-color: #fff;
        border-radius: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .closing-modal-btn{
        width: 45px;
        height: 40px;
        background-color: #000;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        font-size: 150%;
        position:absolute;
        /* padding:  7px 5px 5px 17px; */
        margin-left: -0.445%;
        margin-top: -0.445%;
        cursor: pointer;
        
    }


    .datapoint_count{
        height: 10px;
        width: 10px;
        background-color: #000;
        color: #fff;
    }


}



@media screen  and (min-width:1200px) and (orientation : portrait){
    html {
        box-sizing: border-box;
      }
      *, *:before, *:after {
        box-sizing: inherit;
      }
   
    .header{
        height: 7vh;
        width: 100vw;
        border-bottom: 1px solid black;
        border-top: 1px solid black;
        display: flex;
        justify-content: space-between;
        /* padding: 0.5%; */
        /* align-items: flex-start; */
        overflow:hidden;
    }
    
    
    .icon{
        align-self: center;
        
    }

    .react-icons{
        /* vertical-align: middle; */
        font-size: 200%;
        
    }
    
    .mac-address {
        /* align-self: center; */
        /* margin-left: 40vw; */
        font-size: 100%;
        /* border-right: 40px; */
        /* padding-top: 2.5%; */
        /* padding-bottom: 1.5%; */
        margin: auto;
        /* padding-bottom: 5%; */
    }

    .mac-address h3{
        margin: auto;
    }

    .graph{
        width: 100vw;
        height: 43vh;
    }

    
    
    .graph1{
        height: 35vh;
        width: 99vw;
        margin: auto;
        display: inline-flex;
        /* background-color: gray; */
        /* margin-top: 1rem; */
        /* margin-bottom: 1%; */
        /* margin-left: 3px; */
        /* border-radius: 5px; */
    }


    .line-chart{
        height: 100%;
        width: 100%;

    }

    .graph-sidepanel-open-button{
        position: absolute;
        right: 0px;
        padding: 0;
        margin: 0;
    }

    .side-panel  {
        width:  0px;
        position: fixed;
        z-index: 1;
        height: 250px;
        top: 7vh;
        right: 0px;
        background-color: #111;
        /* overflow-x: hidden; */
        transition: 0.5s;
        padding-top: 60px;
        /* overflow-y: scroll; */
        
      }

      

      .side-panel > button {
        /* padding: 8px 8px 8px 32px; */
        margin: auto;
        text-align: center;
        text-decoration: none;
        font-size: 25px;
        font-family:Verdana, Geneva, Tahoma, sans-serif;
        color: #818181;
        display: block;
        transition: 0.3s;
        cursor: pointer;
        /* border-radius: 10px; */
        background-color: transparent;
        border: none;
      }

      .side-panel > button:hover {
       color: #ffffff;
       background-color: gray;
       border-color: #e6f20c;
       border-radius: 10px;
      }

      #closebutton {
        position: absolute;
        margin-left: 10px;
        top: 0;
        font-size: 36px;
        color:  #000000;
        border: none;
        padding: 0;
        margin-top: 4px;
        background-color: transparent;
        cursor: pointer;
        
      }

      #closebutton :hover {
       background: none;
        
      }
      


    .openbtn {
        font-size: 20px;
        cursor: pointer;
        background-color: #111;
        color: white;
        padding: 10px 15px;
        border: none;
      }
    
    .openbtn  :hover {
        color: #b30000;
        background-color: #b30000;
      }



    

    
    .parameter{
        height: 7vh;
        width: 100%;
        border-radius: 7px;
        margin-top: 0.25rem;
        display: inline-flex;
        padding: 5px;
        
    }

    .parameter > .label2 > label{
        /* border: 1px solid black; */
        display:inline;
        
    }


    .parameter_ul{
        width: 80%;
        height: 100%;
        /* border: 1px solid black; */
        /* display: contents; */
        
    }


    .parameter_ul>ul{
        margin: 0px;
        padding: 0.5%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        /* border: 1px solid black; */
        /* margin-top: -1.2rem; */
        /* justify-content: space-evenly; */
        /* margin-left: 20%; */
    } 

    .parameter_ul > ul > li{
        margin: 0px;
        /* border: 1px solid blue; */
    }

    
    #humid{
        background-color: rgba(107, 119, 227, 0.8);
    }
    
    
    #temp{
        background-color: rgba(222, 60, 99,0.8); 
    }

    
    #lux{
        background-color: rgba(55, 203, 149,0.8);
    }

    
    #motion1{
        background-color: rgba(13, 244, 159, 0.801);
    }
   

    #battery{
        background-color: rgba(255, 255, 0, 0.4);
    }
    

    #voltage{
        background-color: rgba(255, 255, 0, 0.4);
    }


    #pressure{
        background-color: rgba(145, 200, 228, 1);
    }
    

    #gx{
        background-color: rgba(168, 161, 150, 1);
    }
    

    #gy{
        background-color: rgba(255, 217, 192, 1);
    }
    
    
    #gz{
        background-color: rgba(239, 98, 98, 1);
    }
    

    #err{
        background-color: rgba(160, 191, 224, 1);
    }
    
    

    #imei{
        background-color: rgba(203, 255, 169, 1);
    }
    

    #csq{
        background-color: rgba(79, 192, 208, 1);
    }
    

    #fwv{
        background-color: rgba(255, 137, 137, 1);
    }


    #rr{
        background-color: rgba(255, 33, 113, 1);
    }

    #distance_travelled{
        background-color: rgba(168, 161, 150, 1);
    }

    #offline-humidity{
        background-color: rgba(107, 119, 227, 0.349);
    }
    
    #offline-temperature{
        background-color: rgba(222, 60, 98, 0.349);
    }

    #offline-lux{
        background-color: rgba(55, 203, 149,0.349);
    }

    #offline-motion{
        background-color: rgba(13, 244, 159, 0.378);
    }

    #offline-voltage{
        background-color: rgba(255, 255, 0, 0.133);
    }

    #offline-pressure{
        background-color: rgba(145, 200, 228, 0.511);
    }

    #offline-gx{
        background-color: rgba(168, 161, 150, 0.624);
    }

    #offline-gy{
        background-color: rgba(255, 217, 192, 0.352);
    }

    #offline-gz{
        background-color: rgba(239, 98, 98, 0.534);
    }

    #offline-err{
        background-color: rgba(160, 191, 224, 0.438);
    }
    
    #offline-csq{
        background-color: rgba(79, 193, 208, 0.487);
    }

    #offline-rr{
        background-color: rgba(255, 33, 114, 0.655);
    }

    #offline-gsmerr{
        background-color: rgba(159, 104, 59, 0.655);
    }


    
    
    
    .footer{
        height: 7vh;
        width: 100vw;
        border-top: 1px solid black;
        display: flex;
        justify-content: space-around;
        align-items: center;
        
        /* margin-top: 2% ; */
        
    }
    
    /* .stat{
        margin-top: 0.5rem;
    }
    
    .route{
        margin-top: 0.25rem;
    } */

    li {
        font-size: 12px;
        font-weight: 500;
        /* text-align: left; */
        margin-left: 19%;
        
    }

    ul {
        text-align: center;
    }

    .label1{
        
        /* width:140px; */
        margin-right:199px;
        
        
    }

    .label2{
        width: 20%;
        height: 100%;
        
        /* overflow-wrap: break-word; */
        /* word-wrap: break-word; */
        
        /* margin-right: 70%; */
        word-break: break-all;
        -ms-word-break: break-all;
        /* padding-right: 10px; */
        /* text-align: center; */
        hyphens:auto;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        display: inline-block;
        /* border: 1px solid black; */
        
    }

    #resetbutton{
              /* display: flex;   */
              /* justify-content: flex-start; */
              /* display: inline-block; */
        /* border: 1px solid black; */
        margin-right: 2%;
              
    }
    .button2{
        background-color: white; /* Green */
        border: 2px solid #4CAF50;
        color: black;
        padding: 10px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
    }

    .button2:hover {
        background-color: #4CAF50;
        color: white;
    }

    .button3{
        background-color: white; /* Green */
        border: 2px solid #008CBA;
        color: black;
        padding: 10px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
    }

    .button3:hover {
        background-color: #008CBA;
        color: white;
    }

    .button4{
        background-color: white; /* Green */
        border: 2px solid #f44336;
        color: black;
        padding: 10px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
    }

    .button4:hover {
        background-color: #f44336;
        color: white;
    }

  


    .version{
        
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 2vh;
        width: 100vw;
        /* border: 1px solid black; */
    }
    .version>h6{
        /* border: 1px solid black; */
        margin-right: 0.3%;

    }


    /* sizing of map container */

    .map_page_main_div{
        height: 86vh;
        width: 100vw;
    }

    .leaflet-container{
        height: 86vh;
        width: 100%;
        
    }

    /* .markercluster-map { height: 90vh; } */


    
    #customdates{
        /* border: 1px solid black; */
        margin-left: 2%;
    }


    input[type="date"]{
        background-color: #0080ff;
        padding: 10px;
        
        font-family: "Roboto Mono", monospace;
        color: white;
        font-size: 16px;
        border-radius: 5px;
        margin: 0px 2px 0px 2px;
    }

    ::-webkit-calendar-picker-indicator{
        background-color: white;
        padding: 5px;
        cursor: pointer;
        border-radius: 3px;
    }

    .button-dates{
        
           display: inline-flex;
           width: 100vw;
           height: 6vh;
           justify-content: space-between;
           margin-top: 0.5%;
    }
    

    @media print{@page {size: landscape;}}
   

    /* .mapid{
        border: 1px solid black;
        
    } */

    /* } */


    #routedata{
        height: 40%;
        margin-top: 25%;
        
    }

    /* #mapfooter{
        
    } */


    

    

    




    .csvlink-btn{
        background-color: #ffffff;
        border: none;
        cursor: pointer;
    }

    /* .btnContainer{
        
    } */



    .overlay{
        
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(189,189,189,0.9);
        
    }

    .modalContainer{
        position: fixed;
        max-width: 25%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: #ffffff;
        /* height: 40%;  */
        box-shadow: 2.5px 2.5px 10px 1px;
        border-radius: 20px 20px 20px 20px;
        display: flex;
        /* justify-content: flex-end; */
        /* align-items: flex-start; */
        flex-direction: column;
    }

    .btn-print-graph{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #008CBA;
        color: black;
        padding: 10%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 10%;
        /* margin-left: 19%; */
        margin-bottom: 5%;
        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
        
    }

    .btn-print-graph:hover{
        background-color: #008CBA;
        color: white;
        border-radius: 20px 20px 20px 20px;
        
        
    }

    .btn-csv{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #4CAF50;
        color: black;
        padding: 5%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 14%;
        
        /* margin-left: 19%; */

        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
    }

    .btn-csv:hover{
        background-color: #4CAF50;
        color: white;
        border-radius: 20px 20px 20px 20px;
    }

    .btn-offline-csv{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #d60e0e;
        color: black;
        padding: 5%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 14%;
        
        /* margin-left: 19%; */

        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
    }

    .btn-offline-csv:hover{
        background-color: #d60e0e;
        color: white;
        border-radius: 20px 20px 20px 20px;
    }

    .closing-btn{
            margin-right: 4%;
            margin-top: 2.5%;
            border: none;
            background-color: #ffffff;
            font-size: 20px;
            cursor: pointer;
            align-self: flex-end;
    }

    .scheduled_date-btn{
        background-color: #000000;
        padding: 10px;
        /* position: absolute; */
        font-family: "Roboto Mono", monospace;
        color: white;
        font-size: 16px;
        border-radius: 5px;
        margin: 2px;
        transition: 0.4s;
    }

    .scheduled_date-btn:hover{
        color: black;
        background-color: white;
    }


    .scrollable_div{
        
        margin-left:0.5%;
        margin-right:0.5%;
        margin-bottom:0.5%;
        padding:0px 5px 5px 5px;
        height: 40vh;
        width: 99vw;
        overflow-y: scroll;
        /* border: 2px solid black; */
        border-radius: 5px;
        
    }

    .scrollable_div::-webkit-scrollbar {
        width: 5px;
    }

    .scrollable_div::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
        margin-top: 0.25rem;
    }

    .scrollable_div::-webkit-scrollbar-thumb {
        background: black; 
        border-radius: 10px;
    } 

    .scrollable_div::-webkit-scrollbar-thumb:hover {
        background: #b30000; 
    }  

    .component-modal-body{
        height: 25vh;
        width: 99vw;
        border: 2px solid black;
        background-color: #fff;
        border-radius: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .closing-modal-btn{
        width: 45px;
        height: 40px;
        background-color: #000;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        font-size: 150%;
        position:absolute;
        /* padding:  7px 5px 5px 17px; */
        margin-left: -0.445%;
        margin-top: -0.445%;
        cursor: pointer;
        
    }

    .datapoint_count{
        height: 10px;
        width: 10px;
        background-color: #000;
        color: #fff;
    }


}

@media screen  and (min-width:1200px) and (orientation : landscape){
    html {
        box-sizing: border-box;
      }
      *, *:before, *:after {
        box-sizing: inherit;
      }
   
    .header{
        height: 7vh;
        width: 100vw;
        border-bottom: 1px solid black;
        border-top: 1px solid black;
        display: flex;
        justify-content: space-between;
        /* padding: 0.5%; */
        /* align-items: flex-start; */
        overflow:hidden;
    }
    
    
    .icon{
        align-self: center;
        
    }

    .react-icons{
        /* vertical-align: middle; */
        font-size: 200%;
        
    }
    
    .mac-address {
        /* align-self: center; */
        /* margin-left: 40vw; */
        font-size: 100%;
        /* border-right: 40px; */
        /* padding-top: 2.5%; */
        /* padding-bottom: 1.5%; */
        margin: auto;
        /* padding-bottom: 5%; */
    }

    .mac-address h3{
        margin: auto;
    }

    .graph{
        width: 100vw;
        height: 43vh;
    }

    
    
    .graph1{
        height: 75%;
        width: 99vw;
        margin: auto;
        display: inline-flex;
        /* background-color: gray; */
        /* margin-top: 1rem; */
        /* margin-bottom: 1%; */
        /* margin-left: 3px; */
        /* border-radius: 5px; */
    }

    .line-chart{
        height: 100%;
        width: 100%;

    }

    .graph-sidepanel-open-button{
        position: absolute;
        right: 0px;
        padding: 0;
        margin: 0;
    }

    .side-panel  {
        width:  0px;
        position: fixed;
        z-index: 1;
        height: 250px;
        top: 7vh;
        right: 0px;
        background-color: #111;
        /* overflow-x: hidden; */
        transition: 0.5s;
        padding-top: 60px;
        /* overflow-y: scroll; */
        
      }

      

      .side-panel > button {
        /* padding: 8px 8px 8px 32px; */
        margin: auto;
        text-align: center;
        text-decoration: none;
        font-size: 25px;
        font-family:Verdana, Geneva, Tahoma, sans-serif;
        color: #818181;
        display: block;
        transition: 0.3s;
        cursor: pointer;
        /* border-radius: 10px; */
        background-color: transparent;
        border: none;
      }

      .side-panel > button:hover {
       color: #ffffff;
       background-color: gray;
       border-color: #e6f20c;
       border-radius: 10px;
      }

      #closebutton {
        position: absolute;
        margin-left: 10px;
        top: 0;
        font-size: 36px;
        color:  #000000;
        border: none;
        padding: 0;
        margin-top: 4px;
        background-color: transparent;
        cursor: pointer;
        
      }

      #closebutton :hover {
       background: none;
        
      }
      


    .openbtn {
        font-size: 20px;
        cursor: pointer;
        background-color: #111;
        color: white;
        padding: 10px 15px;
        border: none;
      }
    
    .openbtn  :hover {
        color: #b30000;
        background-color: #b30000;
      }



    

    
    .parameter{
        height: 7vh;
        width: 100%;
        border-radius: 7px;
        margin-top: 0.25rem;
        display: inline-flex;
        padding: 5px;
        
    }

    .parameter > .label2 > label{
        /* border: 1px solid black; */
        display:inline;
        
    }


    .parameter_ul{
        width: 80%;
        height: 100%;
        /* border: 1px solid black; */
        /* display: contents; */
        
    }


    .parameter_ul>ul{
        margin: 0px;
        padding: 0.5%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        /* border: 1px solid black; */
        /* margin-top: -1.2rem; */
        /* justify-content: space-evenly; */
        /* margin-left: 20%; */
    } 

    .parameter_ul > ul > li{
        margin: 0px;
        /* border: 1px solid blue; */
    }

    
    #humid{
        background-color: rgba(107, 119, 227, 0.8);
    }
    
    
    #temp{
        background-color: rgba(222, 60, 99,0.8); 
    }

    
    #lux{
        background-color: rgba(55, 203, 149,0.8);
    }

    
    #motion1{
        background-color: rgba(13, 244, 159, 0.801);
    }
   

    #battery{
        background-color: rgba(255, 255, 0, 0.4);
    }
    

    #voltage{
        background-color: rgba(255, 255, 0, 0.4);
    }


    #pressure{
        background-color: rgba(145, 200, 228, 1);
    }
    

    #gx{
        background-color: rgba(168, 161, 150, 1);
    }
    

    #gy{
        background-color: rgba(255, 217, 192, 1);
    }
    
    
    #gz{
        background-color: rgba(239, 98, 98, 1);
    }
    

    #err{
        background-color: rgba(160, 191, 224, 1);
    }
    
    

    #imei{
        background-color: rgba(203, 255, 169, 1);
    }
    

    #csq{
        background-color: rgba(79, 192, 208, 1);
    }
    

    #fwv{
        background-color: rgba(255, 137, 137, 1);
    }


    #rr{
        background-color: rgba(255, 33, 113, 1);
    }

    #distance_travelled{
        background-color: rgba(168, 161, 150, 1);
    }

    #offline-humidity{
        background-color: rgba(107, 119, 227, 0.349);
    }
    
    #offline-temperature{
        background-color: rgba(222, 60, 98, 0.349);
    }

    #offline-lux{
        background-color: rgba(55, 203, 149,0.349);
    }

    #offline-motion{
        background-color: rgba(13, 244, 159, 0.378);
    }

    #offline-voltage{
        background-color: rgba(255, 255, 0, 0.133);
    }

    #offline-pressure{
        background-color: rgba(145, 200, 228, 0.511);
    }

    #offline-gx{
        background-color: rgba(168, 161, 150, 0.624);
    }

    #offline-gy{
        background-color: rgba(255, 217, 192, 0.352);
    }

    #offline-gz{
        background-color: rgba(239, 98, 98, 0.534);
    }

    #offline-err{
        background-color: rgba(160, 191, 224, 0.438);
    }
    
    #offline-csq{
        background-color: rgba(79, 193, 208, 0.487);
    }

    #offline-rr{
        background-color: rgba(255, 33, 114, 0.655);
    }

    #offline-gsmerr{
        background-color: rgba(159, 104, 59, 0.655);
    }




    
    .footer{
        height: 7vh;
        width: 100vw;
        border-top: 1px solid black;
        display: flex;
        justify-content: space-around;
        align-items: center;
        
        /* margin-top: 2% ; */
        
    }
    
    /* .stat{
        margin-top: 0.5rem;
    }
    
    .route{
        margin-top: 0.25rem;
    } */

    li {
        font-size: 12px;
        font-weight: 500;
        /* text-align: left; */
        margin-left: 19%;
        
    }

    ul {
        text-align: center;
    }

    .label1{
        
        /* width:140px; */
        margin-right:199px;
        
        
    }

    .label2{
        width: 20%;
        height: 100%;
        
        /* overflow-wrap: break-word; */
        /* word-wrap: break-word; */
        
        /* margin-right: 70%; */
        word-break: break-all;
        -ms-word-break: break-all;
        /* padding-right: 10px; */
        /* text-align: center; */
        hyphens:auto;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        display: inline-block;
        /* border: 1px solid black; */
        
    }

    #resetbutton{
              /* display: flex;   */
              /* justify-content: flex-start; */
              /* display: inline-block; */
        /* border: 1px solid black; */
        margin-right: 2%;
        height: 90%;
              
    }
    .button2{
        background-color: white; /* Green */
        border: 2px solid #4CAF50;
        color: black;
        padding: 5px 16px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 12px;
        margin: 4px 2px ;
        transition-duration: 0.4s;
        cursor: pointer;
        height: 85%;
    }

    .button2:hover {
        background-color: #4CAF50;
        color: white;
    }

    .button3{
        background-color: white; /* Green */
        border: 2px solid #008CBA;
        color: black;
        padding: 5px 16px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 12px;
        margin: 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
        height: 85%;
    }

    .button3:hover {
        background-color: #008CBA;
        color: white;
    }

    .button4{
        background-color: white; /* Green */
        border: 2px solid #f44336;
        color: black;
        padding: 5px 16px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 12px;
        margin: 4px 2px 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
        height: 85%;
    }

    .button4:hover {
        background-color: #f44336;
        color: white;
    }

  


    .version{
        
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 2vh;
        width: 100vw;
        /* border: 1px solid black; */
    }
    .version>h6{
        /* border: 1px solid black; */
        margin-right: 0.3%;

    }


    /* sizing of map container */

    .map_page_main_div{
        height: 86vh;
        width: 100vw;
    }

    .leaflet-container{
        height: 86vh;
        width: 100%;
        
    }

    /* .markercluster-map { height: 90vh; } */


    
    #customdates{
        /* border: 1px solid black; */
        margin-left: 2%;
        height: 90%;
    }


    input[type="date"]{
        background-color: #0080ff;
        padding: 12px;
        
        font-family: "Roboto Mono", monospace;
        color: white;
        font-size: 16px;
        border-radius: 5px;
        margin: 0px 2px 0px 2px;
        height: 90%;
    }

    ::-webkit-calendar-picker-indicator{
        background-color: white;
        padding: 5px;
        cursor: pointer;
        border-radius: 3px;
    }

    .button-dates{
        
           display: inline-flex;
           width: 100vw;
           height: 25%;
           justify-content: space-between;
           margin-top: 0.5%;
    }
    

    @media print{@page {size: landscape;}}
   

    /* .mapid{
        border: 1px solid black;
        
    } */

    /* } */


    #routedata{
        height: 40%;
        margin-top: 25%;
        
    }

    /* #mapfooter{
        
    } */


    

    

    




    .csvlink-btn{
        background-color: #ffffff;
        border: none;
        cursor: pointer;
    }

    /* .btnContainer{
        
    } */



    .overlay{
        
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(189,189,189,0.9);
        
    }

    .modalContainer{
        position: fixed;
        max-width: 25%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: #ffffff;
        /* height: 40%;  */
        box-shadow: 2.5px 2.5px 10px 1px;
        border-radius: 20px 20px 20px 20px;
        display: flex;
        /* justify-content: flex-end; */
        /* align-items: flex-start; */
        flex-direction: column;
    }

    .btn-print-graph{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #008CBA;
        color: black;
        padding: 10%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 10%;
        /* margin-left: 19%; */
        margin-bottom: 5%;
        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
        
    }

    .btn-print-graph:hover{
        background-color: #008CBA;
        color: white;
        border-radius: 20px 20px 20px 20px;
        
        
    }

    .btn-csv{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #4CAF50;
        color: black;
        padding: 5%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 14%;
        
        /* margin-left: 19%; */

        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
    }

    .btn-csv:hover{
        background-color: #4CAF50;
        color: white;
        border-radius: 20px 20px 20px 20px;
    }
    .btn-offline-csv{
        background-color: #ffffff;
        /* border: none; */
        cursor: pointer;
        /* background-color: white; Green */
        border: 2px solid #d60e0e;
        color: black;
        padding: 5%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 6px;
        transition-duration: 0.4s;
        margin-top: 14%;
        
        /* margin-left: 19%; */

        box-shadow: 0px 0px 5px 0px;
        border-radius: 20px 20px 20px 20px;
    }

    .btn-offline-csv:hover{
        background-color: #d60e0e;
        color: white;
        border-radius: 20px 20px 20px 20px;
    }

    .closing-btn{
            margin-right: 4%;
            margin-top: 2.5%;
            border: none;
            background-color: #ffffff;
            font-size: 20px;
            cursor: pointer;
            align-self: flex-end;
    }

    .scheduled_date-btn{
        background-color: #000000;
        padding: 4px 8px;
        /* position: absolute; */
        font-family: "Roboto Mono", monospace;
        color: white;
        font-size: 16px;
        border-radius: 5px;
        margin: 0px 2px;
        transition: 0.4s;
        height: 90%;
    }

    .scheduled_date-btn:hover{
        color: black;
        background-color: white;
    }


    .scrollable_div{
        
        margin-left:0.5%;
        margin-right:0.5%;
        margin-bottom:0.5%;
        padding:0px 5px 5px 5px;
        height: 40vh;
        width: 99vw;
        overflow-y: scroll;
        /* border: 2px solid black; */
        border-radius: 5px;
        
    }

    .scrollable_div::-webkit-scrollbar {
        width: 5px;
    }

    .scrollable_div::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
        margin-top: 0.25rem;
    }

    .scrollable_div::-webkit-scrollbar-thumb {
        background: black; 
        border-radius: 10px;
    } 

    .scrollable_div::-webkit-scrollbar-thumb:hover {
        background: #b30000; 
    }  

    .component-modal-body{
        height: 25vh;
        width: 99vw;
        border: 2px solid black;
        background-color: #fff;
        border-radius: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .closing-modal-btn{
        width: 45px;
        height: 40px;
        background-color: #000;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        font-size: 150%;
        position:absolute;
        /* padding:  7px 5px 5px 17px; */
        margin-left: -0.445%;
        margin-top: -0.445%;
        cursor: pointer;
        
    }

    .datapoint_count{
        position: fixed;
        height: 100px;
        width: 100px;
        background-color: #000;
        color: #fff;
    }


}