
@media screen and (max-width:600px) {
        
    #lp{
        /* display: flex; */
        /* justify-content: center; */
        padding:10%;
        margin-top:5vh;
        margin-bottom: 5vh;
        margin-left: 5vh;
        margin-right: 5vh;
        
        font-size: x-large;
        border: 1px solid black;
        width: 80%;
        height: 90vh;
        /* align-items: center; */
        box-shadow: 0px 0px 15px 4px;
    }

    img{
        margin-right: 7%;
        width: 80%;
        margin-left: 7%;
        margin-bottom: 50%;
        
    }

    .mac-limi{
        /* justify-content: center;
        align-items: center; */
    }
    
    #limit1{
        text-align: center;
        margin-top: 10%;
        position: relative;
    
    
    }
    
    #submitbutton{
        /* text-align: center; */
        /* margin-left: -2vw; */
        text-align: center;
        justify-content: center;
        margin-top: 5%;
    }
    
    .button1{
        background-color: #4CAF50; /* Green */
        border: none;
        color: white;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 12%;
        /* -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: black; */
    }
    
    input[type=text]{
        border: none;
        border-bottom: 2px solid gray;
        width: 90%;
        outline: none;
        margin-top: 10%;
        margin-bottom: 10%;
        /* font-size: large; */
        text-transform: uppercase;
    }
    
    input[type=number]{
        border: none;
        border-bottom: 2px solid gray;
        width: 90%;
        outline: none;
        margin-top: 7.5%;
    
    }
    #mac-address>label{
        border: 2px solid black;
        padding-left: 6.5%;
        padding-right: 6.5%;
        padding-top: 2.5%;
        padding-bottom: 2.5%;
        border-radius: 15px;
        background-color: black;
        color: white;
    }
    
    #limit1>label{
        border: 2px solid black;
        padding-left: 20%;
        padding-right: 20%;
        padding-top: 1%;
        padding-bottom: 1%;
        border-radius: 15px;
        background-color: black;
        color: white;
    }
    
    #mac-address{
        text-align: center;
    }
    
}



@media screen and (min-width: 600px){
    #lp{
        display: flex;
        justify-content: center;
        /* margin:auto; */
        /* margin-top: 9%; */
        margin-top: 10vh;
        margin-bottom: 10vh;
        margin-left: 10%;
        margin-right: 10%;
        font-size: x-large;
        border: 1px solid black;
        width: 80%;
        height: 80vh;
        align-items: center;
        box-shadow: 0px 0px 15px 4px;
    }
    
    .mac-limi{
        /* justify-content: center;
        align-items: center; */
    }
    
    #limit1{
        text-align: center;
        margin-top: 10%;
        position: relative;
    
    
    }
    
    #submitbutton{
        /* text-align: center; */
        /* margin-left: -2vw; */
        text-align: center;
        justify-content: center;
        margin-top: 5%;
    }
    
    .button1{
        background-color: #4CAF50; /* Green */
        border: none;
        color: white;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 12%;
        /* -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: black; */
    }
    
    input[type=text]{
        border: none;
        border-bottom: 2px solid gray;
        width: 90%;
        outline: none;
        margin-top: 10%;
        margin-bottom: 10%;
        /* font-size: large; */
        text-transform: uppercase;
    }
    
    input[type=number]{
        border: none;
        border-bottom: 2px solid gray;
        width: 90%;
        outline: none;
        margin-top: 7.5%;
    
    }
    #mac-address>label{
        border: 2px solid black;
        padding-left: 6.5%;
        padding-right: 6.5%;
        padding-top: 2.5%;
        padding-bottom: 2.5%;
        border-radius: 15px;
        background-color: black;
        color: white;
    }
    
    #limit1>label{
        border: 2px solid black;
        padding-left: 20%;
        padding-right: 20%;
        padding-top: 1%;
        padding-bottom: 1%;
        border-radius: 15px;
        background-color: black;
        color: white;
    }
    
    #mac-address{
        text-align: center;
    }
    
    
    img{
        margin-right: 5%;
        width: 50%;
        
    }
       
}



@media screen and (min-width: 900px){
    #lp{
        display: flex;
        justify-content: center;
        /* margin:auto; */
        /* margin-top: 9%; */
        margin-top: 10vh;
        margin-bottom: 10vh;
        margin-left: 10%;
        margin-right: 10%;
        font-size: x-large;
        border: 1px solid black;
        width: 80%;
        height: 80vh;
        align-items: center;
        box-shadow: 0px 0px 15px 4px;
    }
    
    .mac-limi{
        /* justify-content: center;
        align-items: center; */
    }
    
    #limit1{
        text-align: center;
        margin-top: 10%;
        position: relative;
    
    
    }
    
    #submitbutton{
        /* text-align: center; */
        /* margin-left: -2vw; */
        text-align: center;
        justify-content: center;
        margin-top: 5%;
    }
    
    .button1{
        background-color: #4CAF50; /* Green */
        border: none;
        color: white;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 12%;
        /* -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: black; */
    }
    
    input[type=text]{
        border: none;
        border-bottom: 2px solid gray;
        width: 90%;
        outline: none;
        margin-top: 10%;
        margin-bottom: 10%;
        /* font-size: large; */
        text-transform: uppercase;
    }
    
    input[type=number]{
        border: none;
        border-bottom: 2px solid gray;
        width: 90%;
        outline: none;
        margin-top: 7.5%;
    
    }
    #mac-address>label{
        border: 2px solid black;
        padding-left: 6.5%;
        padding-right: 6.5%;
        padding-top: 2.5%;
        padding-bottom: 2.5%;
        border-radius: 15px;
        background-color: black;
        color: white;
    }
    
    #limit1>label{
        border: 2px solid black;
        padding-left: 20%;
        padding-right: 20%;
        padding-top: 1%;
        padding-bottom: 1%;
        border-radius: 15px;
        background-color: black;
        color: white;
    }
    
    #mac-address{
        text-align: center;
    }
    
    
    img{
        margin-right: 5%;
        width: 50%;
        
    }
       
}

@media screen and (min-width: 1200px){
    #lp{
        display: flex;
        justify-content: center;
        /* margin:auto; */
        /* margin-top: 9%; */
        margin-top: 10vh;
        margin-bottom: 10vh;
        margin-left: 10%;
        margin-right: 10%;
        font-size: x-large;
        border: 1px solid black;
        width: 80%;
        height: 80vh;
        align-items: center;
        box-shadow: 0px 0px 15px 4px;
    }
    
    .mac-limi{
        /* justify-content: center;
        align-items: center; */
    }
    
    #limit1{
        text-align: center;
        margin-top: 10%;
        position: relative;
    
    
    }
    
    #submitbutton{
        /* text-align: center; */
        /* margin-left: -2vw; */
        text-align: center;
        justify-content: center;
        margin-top: 5%;
    }
    
    .button1{
        background-color: #4CAF50; /* Green */
        border: none;
        color: white;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 12%;
        /* -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: black; */
    }
    
    input[type=text]{
        border: none;
        border-bottom: 2px solid gray;
        width: 90%;
        outline: none;
        margin-top: 10%;
        margin-bottom: 10%;
        /* font-size: large; */
        text-transform: uppercase;
    }
    
    input[type=number]{
        border: none;
        border-bottom: 2px solid gray;
        width: 90%;
        outline: none;
        margin-top: 7.5%;
    
    }
    #mac-address>label{
        border: 2px solid black;
        padding-left: 6.5%;
        padding-right: 6.5%;
        padding-top: 2.5%;
        padding-bottom: 2.5%;
        border-radius: 15px;
        background-color: black;
        color: white;
    }
    
    #limit1>label{
        border: 2px solid black;
        padding-left: 20%;
        padding-right: 20%;
        padding-top: 1%;
        padding-bottom: 1%;
        border-radius: 15px;
        background-color: black;
        color: white;
    }
    
    #mac-address{
        text-align: center;
    }
    
    
    img{
        margin-right: 5%;
        width: 50%;
        
    }
       
}




